<template>
  <StallionDialog
    v-model="show"
    :width="500"
    persistent
    title="Print Packing Slips"
  >
    <template #content>
      <v-layout v-if="loading">
        <v-flex xs12>
          <div
            class="loading-content"
            style="margin: auto"
          >
            <atom-spinner
              style="margin: auto"
              :animation-duration="1000"
              :size="100"
              :color="'#f9ac40'"
            />
            <div class="dialog-info">
              Generating your packing
              {{ items.length | pluralize('slip') }}
            </div>
          </div>
        </v-flex>
      </v-layout>

      <v-layout
        v-else
        wrap
      >
        <v-flex
          xs12
          text-center
        >
          <div class="dialog-info">
            {{ items.length }} packing
            {{ items.length | pluralize('slip') }} ready to be printed
          </div>
        </v-flex>

        <v-flex
          xs12
          text-center
        >
          <v-select
            v-model="packing_slip_template_id"
            name="template"
            label="Packing Slip Template"
            :items="packingSlipTemplates"
            item-text="name"
            item-value="id"
            :clearable="true"
            placeholder="Default Packing Slip"
            persistent-placeholder
          />
        </v-flex>

        <v-flex
          v-if="items.length > 1"
          xs12
        >
          <v-select
            v-model="sort_by"
            label="Sort By"
            :items="sort_items"
          />
        </v-flex>
        <v-flex
          v-if="items.length > 1"
          xs12
        >
          <v-select
            v-model="sort_order"
            label="Sort Order"
            :items="sort_order_items"
          />
        </v-flex>
      </v-layout>
    </template>

    <template #actions>
      <v-btn
        color="primary"
        download
        outlined
        :disabled="loading"
        @click.prevent="getPackingSlips('download')"
      >
        Download
      </v-btn>

      <v-btn
        color="primary"
        download
        :disabled="loading"
        @click.prevent="getPackingSlips('print')"
      >
        Print
      </v-btn>
    </template>
  </StallionDialog>
</template>

<script>
import { AtomSpinner } from 'epic-spinners';
import _find from 'lodash/find';
import _isFunction from 'lodash/isFunction'

export default {
  components: {
    AtomSpinner
  },
  props: {
    items: {
      type: Array,
      default: () => []
    },
    disabled: {
      default: false,
      type: Boolean
    },
    value: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'shipments'
    }
  },

  data() {
    return {
      loading: false,
      sort_items: [
        { text: 'Created At', value: 'id' },
        { text: 'Order ID', value: 'order_id' },
        { text: 'SKU', value: 'sku' },
        { text: 'Recipient', value: 'name' },
        { text: 'Import Order', value: 'row' },
        { text: 'Warehouse Location', value: 'warehouse_location' },
      ],
      sort_order_items: [
        { text: 'Descending', value: 'desc' },
        { text: 'Ascending', value: 'asc' }
      ],
      sort_by_sku_obj: { text: 'SKU', value: 'sku' },
      packing_slip_template_id: null,
      sort_by: 'id',
      sort_order: 'asc',
      label_name: null,
      status: null,
      max_ship: 50,
      name: null
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    },
    packingSlipTemplates() {
      return this.$store.getters['packingSlipTemplates/templates'];
    }
  },
  watch: {
    show: {
      handler() {
        if (this.show) {
          this.setDefaultPackingSlipTemplate();
        }
      }
    }
  },
  mounted() {
    this.updateSortByOptions()
  },

  methods: {
    setDefaultPackingSlipTemplate() {
      if (this.packingSlipTemplates.length == 0) {
        return;
      }

      const defaultTemplate = _find(this.packingSlipTemplates, { is_default: 1 });
      if (defaultTemplate) {
        this.packing_slip_template_id = defaultTemplate.id;
      }
    },
    getPackingSlips: function (action = 'download', printCallBack) {
      this.loading = true;
      if (this.items.length > this.max_ship && this.packing_slip_template_id !== null  && this.$router.currentRoute.name !== 'orders-show') {
        this.queuePackingSlip();
      } else {
        var ids = [];
        this.items.forEach(function (item) {
          ids.push(item.id);
        });
        this.$http
            .post(
                `packing-slips/${this.type}`,
                {
                  ids: ids,
                  sort_by: this.sort_by,
                  sort_order: this.sort_order,
                  packing_slip_template_id: this.packing_slip_template_id
                }
            )
            .then((response) => {
              if (action == 'print') {
                this.printUrl(response.data, true);
              } else {
                this.downloadFileUrl(response.data, 'StallionLabels.pdf');
              }

              if (_isFunction(printCallBack)) {
                printCallBack()
              }
            })
            .catch(() => {
              if (_isFunction(printCallBack)) {
                printCallBack()
              }
            })
            .finally(() => {
              this.loading = false;
            });
      }
    },
    /**
     * Queue the packingslip to be created
     */
    queuePackingSlip: function () {
      var ids = [];
      this.items.forEach(function (item) {
        ids.push(item.id);
      });

      this.$http
          .post(`/shipments/printLarge`,
              {
                ids: ids,
                sort_by: this.sort_by,
                sort_order: this.sort_order,
                name: "stallion_packing_slip",
                packing_slip_template_id: this.packing_slip_template_id,
                type: 'packing_slip_only',
                label_format: 'pdf',
          })
          .then(() => {
            this.$router.push('/print-jobs');
          })
          .catch(() => {
            this.loading = false;
          });
    },
    updateSortByOptions() {
      this.type == 'shipments' ? this.sort_items.push(this.sort_by_sku_obj) : ''
    },

    setPackingSlipData(printingSettings) {
      const keysToUpdate = ['sort_by', 'sort_order', 'packing_slip_template_id'];

      keysToUpdate.forEach(key => {
        if (printingSettings[key] !== undefined) {
          this[key] = printingSettings[key];
        }
      });
    },
  }
};
</script>
