<template>
  <div
    v-if="
      shipment &&
        (shipment.handling_fee || shipment.postage_rate || shipment.tax) > 0
    "
  >
    <h4 v-if="showHeader">
      Postage
    </h4>
    <div
      v-if="shipment.postage_type_id"
      class="my-1"
      v-html="$options.filters.postage(shipment)"
    />

    <!-- <div class="mb-3">
      {{ shipment.tier }}
    </div> -->

    <div
      v-if="shipment.payment_at"
      class="rate-block"
    >
      <div class="d-flex flex-row justify-space-between">
        <div class="shipment-title">
          Payment Date
        </div>
        <div>
          {{ shipment.payment_at | date_pretty }}
        </div>
      </div>
    </div>

    <div
      v-if="rateDetails.rate"
      class="d-flex flex-row justify-space-between"
    >
      <div class="shipment-title">
        Postage Fee
      </div>

      <div>
        {{ rateDetails.base_rate | currency }}
      </div>
    </div>

    <div
      v-if="rateDetails.add_ons.length > 0"
      class="d-flex flex-row justify-space-between"
    >
      <div class="shipment-title">
        Add Ons
      </div>
      <div
        v-for="addon in rateDetails.add_ons"
        :key="addon.type"
      >
        <div class="text-muted">
          {{ addon.type | capitalize }}: {{ addon.cost | currency }}
        </div>
      </div>
    </div>

    <div
      v-if="rateDetails.duty_tax && rateDetails.duty_tax > 0"
      class="d-flex flex-row justify-space-between"
    >
      <div class="shipment-title">
        Duty & Tax
      </div>
      <div>
        {{ (rateDetails.duty_tax + rateDetails.duty) | currency }}
      </div>
    </div>

    <div
      v-if="rateDetails.tax && rateDetails.tax > 0"
      class="d-flex flex-row justify-space-between"
    >
      <div class="shipment-title">
        Tax
      </div>
      <div>{{ rateDetails.tax | currency }}</div>
    </div>

    <div class="d-flex flex-row justify-space-between">
      <div class="shipment-title font-weight-bold">
        Total
      </div>
      <div>
        {{ rateDetails.total | currency }}
      </div>
    </div>
    <v-divider
      v-if="showDivider"
      class="details-divider"
    />
  </div>
</template>

<script>
export default {
  props: {
    shipment: {
      type: Object,
      default: null
    },
    showHeader: {
      type: Boolean,
      default: true
    },
    showDivider: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    rateDetails() {
      return this.shipment.rate_details;
    }
  }
};
</script>
