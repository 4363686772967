<template>
  <div>
    <v-container
      v-if="shipment"
      grid-list-md
      mb-12
    >
      <v-row
        align="top"
        justify="center"
      >
        <v-col cols="7">
          <v-card>
            <v-card-text>
              <v-form @submit.prevent="save">
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <h2>Label Details</h2>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="12"
                      md="5"
                    >
                      <user-search
                        v-model="shipment.user_id"
                        label="User Id"
                        :outlined="false"
                        :dense="false"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      md="7"
                    >
                      <v-text-field
                        v-model="shipment.barcode"
                        v-validate="'required'"
                        :error-messages="errs.collect('barcode')"
                        label="Barcode"
                        data-vv-name="barcode"
                      />
                    </v-col>

                    <v-col
                      cols="12"
                      md="8"
                    >
                      <v-text-field
                        v-model="shipment.item"
                        :error-messages="errs.collect('contents')"
                        label="Contents"
                        data-vv-name="contents"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      md="8"
                    >
                      <v-text-field
                        v-model="shipment.value"
                        :error-messages="errs.collect('value')"
                        label="Retail Value"
                        data-vv-name="value"
                      />
                    </v-col>

                    <v-col
                      cols="12"
                      md="4"
                    >
                      <v-text-field
                        v-model="shipment.weight"
                        v-validate="''"
                        :error-messages="errs.collect('weight')"
                        label="Weight (lbs)"
                        data-vv-name="weight"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      md="4"
                    >
                      <v-select
                        v-model="shipment.status_id"
                        :items="statuses"
                        item-value="id"
                        item-text="description"
                        label="Status"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="12"
                      md="6"
                    >
                      <v-select
                        v-model="shipment.current_location_id"
                        :items="locations"
                        item-value="id"
                        item-text="text"
                        label="Current Location"
                        hint="The location where the return item currently is"
                        persistent-hint
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                    >
                      <v-select
                        v-model="shipment.destination_location_id"
                        :items="locations"
                        item-value="id"
                        item-text="text"
                        label="Destination Location"
                        hint="The location where the return item needs to go"
                        persistent-hint
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>

            <v-divider />

            <v-col
              justify="center"
            >
              <v-btn
                color="primary"
                :loading="loading"
                :disabled="loading"
                @click="submit()"
              >
                Confirm
              </v-btn>
            </v-col>
          </v-card>
        </v-col>
        <v-col
          cols="5"
          justify="center"
        >
          <v-card>
            <v-carousel
              v-if="shipment.content_images.length > 0"
              show-arrows-on-hover
              hide-delimiter-background
            >
              <v-carousel-item :src="shipment.full_image_url" />
              <v-carousel-item
                v-for="(item, i) in shipment.content_images"
                :key="i"
                :src="item.full_image_url"
              />
            </v-carousel>

            <v-img
              v-else
              contain
              :src="shipment.full_image_url"
            />
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-menu v-if="shipment.images_required">
            <template #activator="{ on }">
              <v-btn
                class="ml-3"
                large
                color="primary"
                v-on="on"
                @click="$refs.uploadImages.openDialog()"
              >
                Add images
              </v-btn>
            </template>
          </v-menu>
          
          <upload-images
            ref="uploadImages"
            :shipment-return="shipment"
          />
        </v-col>
      </v-row>
    </v-container>

    <div class="text-left footer">
      <!-- <v-btn
        color="primary"
        @click="requestReturn()"
        v-if="
          shipment && shipment.status_id === 30 && shipment.service_id !== 2
        "
        :loading="requestReturnLoading"
        >Request Return</v-btn
      >-->
    </div>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      module: 'Dashboard',
      shipment: null,
      searchID: null,
      userIDs: null,
      requestPhotoLoading: false,
      statuses: [],
      locations: [],
      status: 0,
      genLabelLoading: false,
      show: false,
      loading: false,
      new_return: {},

      sender: null,
      rows: [
        {
          description: null,
          hs_code: null,
          country_of_origin: 'US',
          value: null,
          quantity: 1,
          vat_rate_estimate: null,
          duty_rate_estimate: null
        }
      ]
    };
  },
  created() {
    this.getShipmentStatuses();
    this.getReturn();
    this.getLocations();
  },
  methods: {
    getShipmentStatuses() {
      this.$http
        .get(`/return-statuses`)
        .then(response => response.data)
        .then(response => {
          this.statuses = response;
        });
    },

    submit: function() {
      this.$validator.validate().then(result => {
        if (result) {
          this.loading = true;

          this.$http
            .put('admin/returns/' + this.shipment.id, this.shipment)
            .then(response => response.data)
            .then(response => {
              console.log(response);
              swal({
                title: 'Success',
                text: 'Return updated successfully',
                icon: 'success'
              });
              this.$router.push({
                name: 'admin-return-show',
                params: {
                  id: this.shipment.id
                }
              });
            })
            .catch(err => {
              if (err.response.status === 422) {
                swal({
                  title: 'Error',
                  text: 'Validation error',
                  icon: 'error'
                });
              }

              if (err.response.status === 500) {
                swal({
                  title: 'Error',
                  text: 'Server error',
                  icon: 'error'
                });
              }
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },

    requestAdditionalPhotos() {
      this.requestPhotoLoading = true;
      var ids = [];
      ids.push(this.$route.params.id);
      this.$http
        .post('/admin/returns/update-statuses', {
          ids: ids,
          status: this.status
        })
        .then(response => {
          if (response.data.success) {
            swal({
              title: 'Success',
              text: 'Requested additional photo(s) successfully',
              icon: 'success'
            });
          } else {
            swal({
              title: 'Error',
              text: 'Something went wrong.',
              icon: 'error'
            });
          }
        })
        .finally(() => {
          this.requestPhotoLoading = false;
        });
    },

    getReturn() {
      // GET /someUrl
      this.$http
        .get('/admin/returns/' + this.$route.params.id)
        .then(response => {
          console.log(response.data);
          // get body data
          this.shipment = response.data;
        });
    },
    
    getLocations() {
      this.$http
        .get('/admin/dropoff-locations?company_location=true')
        .then(response => {
          // Format the locations for the dropdown
          this.locations = response.data.map(location => ({
            id: location.id,
            text: `${location.name} - ${location.address1}, ${location.city} ${location.province_code}, ${location.postal_code}`
          }));
        })
        .catch(error => {
          console.error('Failed to fetch locations:', error);
        });
    }
  }
};
</script>

<style scoped>
strong {
  color: black;
}
</style>
