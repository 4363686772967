<template>
  <FullPageLayout title="FBA">
    <template #default>
      <v-container>
        <v-row>
          <v-col
            cols="12"
            md="4"
            class="pl-0 pr-4 py-1"
          >
            <v-card
              outlined
              hover
              class="px-5 py-3 fill-height mx-auto"
              @click="pickupRequest"
            >
              <v-list-item class="px-0">
                <v-list-item-avatar
                  size="35"
                  tile
                  class="mt-3 mb-auto"
                >
                  <v-img :src="asset('images/amazon-simple-logo.svg')" />
                </v-list-item-avatar>
                <v-list-item-content>
                  <h4>FBA Pickup</h4>
                  <p class="pt-2 description-text">
                    Shipping to Amazon US warehouses? Stallion Express will
                    pick up from you!
                  </p>
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </v-col>
          <v-col
            cols="12"
            md="4"
            class="pl-0 pr-4 py-1"
          >
            <v-card
              outlined
              hover
              class="px-5 py-3 fill-height mx-auto"
              @click="manifestRequest"
            >
              <v-list-item class="px-0">
                <v-list-item-avatar
                  size="35"
                  tile
                  class="mt-3 mb-auto"
                >
                  <v-icon
                    large
                    color="black"
                  >
                    mdi-package-variant
                  </v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <h4>FBA Manifest</h4>
                  <p class="pt-2 description-text">
                    Manifest your FBA shipments and print your FBA labels.
                  </p>
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
      <div>
        <!-- <v-breadcrumbs
          large
          :items="[
          {
            text: 'Home',
            exact: true,
            to: '/dashboard',
          },
          {
            text: 'Pickups',
            exact: true,
            to: '/pickups',
          },
            {
            text: filter_formated,
            exact: true,
            to: '/pickups?filter='+$route.query.filter,
          },
      
        ]"
        ></v-breadcrumbs> -->
      </div>
      <PickupTable
        v-model="selected"
        :filter="filter"
        :types="['Stallion']"
      />

      <PrintPickupDialog
        v-model="showPrintDialog"
        :pickups="selected"
      />

      <CancelPickupDialog
        v-model="showCancelDialog"
        :pickups="selected"
      />
        
      <FbaPickupDialog />

      <v-dialog
        v-model="showFbaPickupDialog"
        max-width="500"
      >
        <v-card>
          <v-card-title class="text-h5">
            FBA Pickup Unavailable
          </v-card-title>
          <v-card-text>
            FBA pickups are temporarily disabled for the Alberta region only. Please contact support for assistance.
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="primary"
              text
              @click="showFbaPickupDialog = false"
            >
              OK
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
        v-model="showFbaManifestDialog"
        max-width="500"
      >
        <v-card>
          <v-card-title class="text-h5">
            FBA Manifest Unavailable
          </v-card-title>
          <v-card-text>
            FBA Manifest are temporarily disabled for the Alberta region only. Please contact support for assistance.
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="primary"
              text
              @click="showFbaManifestDialog = false"
            >
              OK
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
    <template #footer>
      <v-btn
        outlined
        color="primary"
        :disabled="!pickup_printable"
        @click="showPrintDialog = true"
      >
        <v-icon
          left
          small
        >
          mdi-printer
        </v-icon>

        Print
      </v-btn>
      <!-- <v-btn
        color="primary"
        @click="showCancelDialog = true"
        :disabled="!cancelable"
        >Cancel</v-btn
      >-->
    </template>
  </FullPageLayout>
</template>

<script>
import FbaPickupDialog from '@/components/main/pickups/FbaPickupDialog.vue';

import PrintPickupDialog from '@/components/main/pickups/PrintPickupDialog.vue';
import PickupTable from '@/components/main/pickups/PickupTable.vue';
import CancelPickupDialog from '@/components/main/pickups/CancelPickupDialog.vue';

export default {
  metaInfo: {
    title: "Pickups | Stallion Express"
  },
  components:{
    FbaPickupDialog,
    PrintPickupDialog,
    PickupTable,
    CancelPickupDialog
  },
  props: {
    filter: {
      default: null,
      type: String
    }
  },

  data() {
    return {
      showCancelDialog: false,
      showPrintDialog: false,
      showFbaManifestDialog: false,
      showFbaPickupDialog: false,
      selected: [],
      drawer: null
    };
  },
  computed: {
    filter_formated() {
      return this.$options.filters.capitalize(this.$route.query.filter);
    },

    pickup_printable() {
      if (
        this.selected.length != 0 &&
        this.selected.every((r) => r.has_label === 1)
      ) {
        return true;
      } else {
        return false;
      }
    },
    cancelable() {
      if (
        this.selected.length != 0 &&
        this.selected.every((r) =>
          moment(moment().format('YYYY-MM-DD')).isBefore(
            r.requested_pickup_date
          )
        ) &&
        this.selected.every((r) => r.type == 'UPS')
      ) {
        return true;
      } else {
        return false;
      }
    },
    
    
  },
  methods:{
    manifestRequest: function () {
      // Check if user is in Alberta region
      if (!this.$auth.user.fba_manifest_enabled) {
        this.showFbaManifestDialog = true;
        return;
      }

      // Proceed with FBA manifest for non-Alberta users
      this.$store.state.singleFlow.single_dialog = true;
      Event.fire('edit-shipment', {
        shipment: {
          is_fba: true
        },
        step: 'recipient'
      });
    },
    pickupRequest: function () {
      // Check if user is in Alberta region
      if (!this.$auth.user.fba_pickup_enabled){
        this.showFbaPickupDialog = true;
        return;
      }

      // Proceed with FBA pickup for non-Alberta users
      this.$store.state.main.fba_request_dialog = true;
    },
  },
};
</script>
