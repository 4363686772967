<template>
  <FullPageLayout
    :title="`Supplies Store`"
  >
    <template #subtitle>
      <p>
        Prices displayed for USPS supplies are a service fee.
      </p>
      <p>
        <span style="color: #BA3F3F;">NOTE:</span>
        You can select your pickup branch at the checkout page. Please note
        that supplies will be available for pickup
        <span style="font-weight: 700;">within 24 - 48 hours</span>
      </p>
    </template>
    <template #page-links>
      <v-btn
        class="px-4 mt-5"
        router
        to="/supplies/orders"
        color="primary"
        outlined
      >
        View Orders
      </v-btn>
    </template>
   
    <template #default>
      <v-row>
        <v-col cols="12">
          <v-card
            outlined
            class="mb-3"
          >
            <v-tabs
              v-model="tab"
              outlined
            >
              <v-tab
                v-for="group in groups"
                :key="group"
              >
                <p class="font-weight-bold">
                  {{ group }}
                </p>
              </v-tab>
            </v-tabs>
          </v-card>
        
          <v-tabs-items
            v-model="tab"
            class="mb-100"
          >
            <v-tab-item
              v-for="group in groups"
              :key="group"
            >
              <v-row>
                <!-- Individual Supplies Cards -->
                <v-col
                  v-for="(item, index) in getGroup(group)"
                  :key="group + index"
                  :color="'red'"
                  md="3"
              
                  style="max-width: 280px; max-height: 420px;"
                >
                  <v-card
                    outlined
                    height="100%"
                    class="pa-3"
                  >
                    <v-card-text>
                      <v-layout
                        wrap
                        text-center
                      >
                        <v-img
                          :src="item.image_location"
                          aspect-ratio="1"
                          class="grey lighten-5 my-2 mr-10 ml-10"
                        >
                          <template #placeholder>
                            <v-layout
                              fill-height
                              align-center
                              justify-center
                              ma-0
                            >
                              <v-progress-circular
                                indeterminate
                                color="grey lighten-5"
                              />
                            </v-layout>
                          </template>
                        </v-img>
                      </v-layout>
                      <v-layout
                        wrap
                        mb-1
                      >
                        <v-flex
                          xs12
                          text-left
                        >
                          <h4 class="font-weight-bold">
                            {{ item.name }}
                          </h4>
                          <p class="mb-3">
                            {{ item.units_per_container }} PCS
                          </p>
                          <p
                            v-if="item.dimentions"
                            class="mb-3"
                          >
                            ({{ item.dimentions }})
                          </p>
                        </v-flex>
                      </v-layout>
                      <v-spacer />
                      <v-layout wrap>
                        <v-flex
                          xs4
                          text-left
                        >
                          <h4 class="font-weight-bold">
                            {{ item.effective_price | currency }}
                          </h4>
                        </v-flex>
                        <v-flex
                          xs8
                          text-right
                        >
                          <!--:max="item.quantity_in_stock"-->
                          <NumberInputSpinner
                            v-if="
                              (item.region_location_inventory.quantity_in_stock || 0) > 0
                            "
                            v-model="item.purchase_quantity"
                            :min="0"
                            :max="
                              item.region_location_inventory.quantity_in_stock || 0
                            "
                            button-class="btn-increment"
                            :integer-only="true"
                            style="transform: scale(0.8);"
                            @input="
                              cart.items.find(element => element.id === item.id) &&
                                updateCartItem(item)
                            "
                          />
                          <!--<h2
                      v-if="item.quantity_in_stock == 0"
                      style="color: red; margin-top: 50px"
                    >Out of Stock</h2>-->
                          <h2
                            v-if="
                              (item.region_location_inventory.quantity_in_stock ||
                                0) == 0
                            "
                            style="color: #BA3F3F; margin-top: 50px"
                          >
                            Out of Stock
                          </h2>
                          <!-- <h2 v-else-if="item.quantity_in_stock > 0 && cart.items.find(element => element.id === item.id) "
                    >{{item.quantity_in_stock - item.purchase_quantity}} in Stock</h2>-->
                        </v-flex>
                      </v-layout>

                      <!-- Remove Button -->
                      <!--v-if="item.quantity_in_stock > 0"-->
                      <v-layout
                        v-if="
                          (item.region_location_inventory.quantity_in_stock || 0) > 0
                        "
                        wrap
                        justify-center
                        class="pt-3"
                      >
                        <v-btn
                          v-if="cart.items.find(element => element.id === item.id)"
                          color="red"
                          dark
                          :disabled="
                            !cart.items.find(element => element.id === item.id)
                          "
                          style="width: 100%;"
                          @click="removeProduct(item)"
                        >
                          <span>Remove</span>
                        </v-btn>

                        <!-- Add to Cart Button -->
                        <v-btn
                          v-if="!cart.items.find(element => element.id === item.id)"
                          color="primary"
                          outlined
                          style="width: 100%;"
                          @click="addProduct(item)"
                        >
                          <span>Add to Cart</span>
                        </v-btn>
                      </v-layout>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
    </template>
    <template #footer>
      <transition name="slide-up">
        <div
          v-if="cart.items.length > 0"
          class="footer"
        >
          <v-btn
            color="primary"
            router
            to="/supplies/checkout"
          >
            Checkout
          </v-btn>
          <v-btn text>
            {{ cart.subtotal | currency }}
          </v-btn>
        </div>
      </transition>
    </template>
  </FullPageLayout>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import NumberInputSpinner from 'vue-number-input-spinner';
import { initializeInventory } from '../../../helpers/helper';
export default {
  metaInfo: {
    title: "Supplies Store | Stallion Express"
  },
  components: {
    NumberInputSpinner
  },
  data() {
    return {
      supplies: [],
      groups: [],
      panel: [],
      tab: null,
      location: null,
      locations: [],
    };
  },
  computed: {
    ...mapState(['cart'])
  },
  watch: {
    location: function(newVal) {
      const locationObj = this.locations.find(i => i.id === newVal);
      this.setPickupLocation(locationObj);
      this.getSupplies();
      
      // Recalculate the cart with the new pricing
      if (this.cart.items.length > 0) {
        this.$store.commit('cart/recalculateTotals');
      }
    }
  },
  mounted() {
    this.getInventoryLocations();
  },
  methods: {
    ...mapActions('cart', [
      'addProductToCart',
      'removeProductFromCart',
      'clearCart'
    ]),
    getGroup(group) {
      return this.supplies.filter(obj => {
        return obj.type === group;
      });
    },
    async getInventoryLocations() {
      try {
        const response = await this.$http.get('/supplies/inventory-locations');
        if (response.status === 200) {
          this.locations = response.data.locations;
          this.location = response.data.user_branch.id ?? this.locations[0].id;
          if (this.cart.pickup_location && this.cart.items.length > 0) {
            this.location = this.cart.pickup_location.id;
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getSupplies() {
      try {
        const response = await this.$http.get('/supplies/' + this.location);
        if (response.status === 200) {
          this.supplies = response.data;
          this.supplies.forEach(item => {
            if (item.region_location_inventory === null) {
              const obj = {
                pending_pickup: 0,
                quantity_in_stock: 0,
                location_id: this.location
              };
              item.region_location_inventory = obj;
            }
            initializeInventory(item);
            const cartItem = this.cart.items.find(
              element => element.id === item.id
            );

            if (cartItem) {
              // insert
              item.purchase_quantity = cartItem.purchase_quantity;
            } else {
              // update
              item.purchase_quantity = 1;
            }

            this.groups.push(item.type);
          });

          this.groups = [...new Set(this.groups)];
        }
      } catch (error) {
        console.log(error);
      }
    },
    addProduct(item) {
      // Create a copy of the item with price set to effective_price
      const itemWithEffectivePrice = {...item};
      itemWithEffectivePrice.price = itemWithEffectivePrice.effective_price;
      this.addProductToCart(itemWithEffectivePrice);
    },
    removeProduct(item) {
      this.removeProductFromCart(item);
    },
    setPickupLocation(location) {
      this.$store.commit('cart/setCartPickupLocation', location);
      console.log(this.cart);
    },
    updateCartItem(item) {
      // Create a copy of the item with price set to effective_price
      const itemWithEffectivePrice = {...item};
      itemWithEffectivePrice.price = itemWithEffectivePrice.effective_price;
      this.addProductToCart(itemWithEffectivePrice);
    }
  }
};
</script>

<style scoped>
.v-application p {
  margin-bottom: 0px;
}

.vnis__input {
  height: 20px !important;
  width: calc(100% - 50px) !important;
}

.btn-increment {
  height: 20px;
  width: 20px;
}

.v-expansion-panels {
  z-index: 0;
}
</style>
