<template>
  <StallionDialog
    v-model="add_dialog"
    persistent
    :max-width="600"
    :title="dialogTitle"
  >
    <template #content>
      <v-card-text class="pt-5">
        <v-container grid-list-md>
          <v-layout wrap>
            <!-- hide if view is for specific user -->
            <v-flex
              v-if="!user"
              xs12
            >
              <user-search
                v-model="user_id"
                v-validate="'required'"
                data-vv-name="user_id"
                :error-messages="errs.collect('user_id')"
                @change="selectUser()"
              />
            </v-flex>
            
            <v-flex xs12>
              <v-autocomplete
                v-model="type_id"
                v-validate="'required'"
                :error-messages="errs.collect('history_type')"
                data-vv-name="history_type"
                :items="chargeTypes"
                label="Type"
                item-text="name"
                item-value="id"
                :search-input.sync="searchInput2"
                outlined
                :hint="selected && selected.description"
              />
            </v-flex>

            <v-flex
              v-if="isRecurring"
              xs12
            >
              <v-select
                v-model="schedule"
                v-validate="'required'"
                :error-messages="errs.collect('schedule')"
                data-vv-name="schedule"
                :items="recurringSchedules"
                label="Recurring Schedule"
                item-text="label"
                item-value="key"
                outlined
              />
            </v-flex>

            <v-flex xs12>
              <v-text-field
                v-model="subtotal"
                v-validate="'required|decimal:2|min_value:0.01|max_value:20000'"
                label="Amount"
                type_id="number"
                :error-messages="errs.collect('subtotal')"
                data-vv-name="subtotal"
                required
                outlined
              />
            </v-flex>

            <v-flex
              v-show="selected && selected.tax"
              xs6
            >
              <v-switch
                v-model="taxable"
                readonly
                :label="`Tax Rate: ${localUser?.taxes?.combined_rate || 0 }%`"
              />
            </v-flex>

            <v-flex
              v-show="selected && selected.tax"
              xs6
            >
              <v-text-field
                v-validate="'decimal:2'"
                :value="tax"
                disabled
                label="Total Tax"
                type_id="number"
                :error-messages="errs.collect('tax')"
                data-vv-name="tax"
                required
                outlined
                readonly
              />
            </v-flex>

            <v-flex
              v-show="selected && selected.tax"
              xs3
            >
              <v-text-field
                v-validate="'decimal:2'"
                :value="gst"
                disabled
                label="GST"
                type_id="number"
                :error-messages="errs.collect('gst')"
                data-vv-name="gst"
                required
                outlined
                readonly
              />
            </v-flex>

            <v-flex
              v-show="selected && selected.tax"
              xs3
            >
              <v-text-field
                v-validate="'decimal:2'"
                :value="pst"
                disabled
                label="PST"
                type_id="number"
                :error-messages="errs.collect('pst')"
                data-vv-name="pst"
                required
                outlined
                readonly
              />
            </v-flex>

            <v-flex
              v-show="selected && selected.tax"
              xs3
            >
              <v-text-field
                v-validate="'decimal:2'"
                :value="hst"
                disabled
                label="HST"
                type_id="number"
                :error-messages="errs.collect('hst')"
                data-vv-name="hst"
                required
                outlined
                readonly
              />
            </v-flex>

            <v-flex
              v-show="selected && selected.tax"
              xs3
            >
              <v-text-field
                v-validate="'decimal:2'"
                :value="qst"
                disabled
                label="QST"
                type_id="number"
                :error-messages="errs.collect('qst')"
                data-vv-name="qst"
                required
                outlined
                readonly
              />
            </v-flex>

            <v-flex xs12>
              <v-text-field
                v-model="description"
                v-validate="'required'"
                :error-messages="errs.collect('description')"
                data-vv-name="description"
                label="Description"
                outlined
              />
            </v-flex>

            <v-flex
              v-if="isRecurring"
              xs12
            >
              <v-switch
                v-model="is_active"
                label="Active"
              />
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-text>
    </template>
    
    <template #actions>
      <v-btn
        color="primary"
        text
        :loading="loading"
        :disabled="loading"
        @click.native="submit()"
      >
        Save
      </v-btn>
    </template>
  </StallionDialog>
</template>

<script>
export default {
  props: {
    user: {
      default: null,
      type: [Object, null]
    },
    isRecurring: {
      type: Boolean,
      default: false
    },
    dialogTitle: {
      type: String,
      default: 'Charge User'
    }
  },
  data() {
    return {
      user_id: this.user?.id,
      localUser: this.user,
      recurringData: null,
      type_id: null,
      schedule: 'monthly',
      module: 'Dashboard',
      menu1: '',
      add_dialog: false,
      loading: false,
      subtotal: null,
      description: '',
      is_active: true,
      action: null,
      searchInput1: null,
      searchInput2: null,
      taxable: false,
      disabled: false,

      types: [
        {
          id: 2,
          name: 'Supply Payment',
          description:
            'Customer is buying supplies but our inventory is not correct so we need to do it manually.',
          tax: true,
          recurring: false
        },
        {
          id: 5,
          name: 'Pickup Payment',
          description: 'Charge a customer for pickups.',
          tax: true,
          recurring: true
        },
        {
          id: 7,
          name: 'Penalty Payment',
          description: null,
          tax: false,
          recurring: false
        },
        {
          id: 6,
          name: 'Duty and VAT Payment',
          description: null,
          tax: false,
          recurring: false
        },
        {
          id: 12,
          name: 'Credits Refunded',
          description: 'You have manually paid the customer back credits.',
          recurring: false
        },
        {
          id: 13,
          name: 'Domestic Pallet Handling Fee',
          description:
            'This fee is charged manually for people who ship pallets.',
          tax: true,
          recurring: false
        },
        {
          id: 14,
          name: 'International Pallet Handling Fee',
          description:
            'This fee is charged manually for people who ship pallets.',
          tax: false,
          recurring: false
        },
        {
          id: 15,
          name: 'Delivery Fee',
          description: 'Manual charge for delivering to clients.',
          tax: true,
          recurring: false
        },
        {
          id: 17,
          name: 'Credit Correction',
          description: 'Manually adjust the client credits.',
          tax: false,
          recurring: false
        },
        {
          id: 19,
          name: 'Relabeling Fee',
          description: 'Manually charge for relabeling.',
          tax: true,
          recurring: false
        },
        {
          id: 20,
          name: 'POA Activation Fee',
          description: 'Manually charge for activating POA.',
          tax: true,
          recurring: false
        },
        {
          id: 21,
          name: 'Manual US Shipment',
          description: 'Charge for manually creating a US shipment.',
          tax: false,
          recurring: false
        }
      ],

      // ref: https://laravel.com/docs/9.x/scheduling#schedule-frequency-options
      recurringSchedules: [
        {
          key: 'monthly',
          label: '(Monthly) - On the first day of every month'
        }
      ]
    };
  },
  computed: {
    chargeTypes() {
      if (this.isRecurring) {
        return this.types = this.types.filter((type) => {
          return type.recurring
        })
      }

      return this.types
    },

    selected() {
      const index = this.types.findIndex((x) => x.id === this.type_id);
      const category = this.types[index];

      return category;
    },

    tax() {
      let t =
        Number(this.gst) +
        Number(this.hst) +
        Number(this.qst) +
        Number(this.pst);

      return Math.round(t * 100) / 100;
    },

    total() {
      let t = Number(this.tax) + Number(this.subtotal);

      return Math.round(t * 100) / 100;
    },

    gst() {
      if (!this.localUser) {
        return 0
      }

      if (
        this.taxable &&
        typeof this.localUser?.taxes?.breakdown?.gst !== 'undefined'
      ) {
        let tax = (this.localUser?.taxes?.breakdown?.gst / 100) * this.subtotal;

        return Math.round(tax * 100) / 100;
      } else {
        return 0;
      }
    },

    hst() {
      if (!this.localUser) {
        return 0
      }

      if (
        this.taxable &&
        typeof this.localUser?.taxes?.breakdown?.hst !== 'undefined'
      ) {
        let tax = (this.localUser?.taxes?.breakdown?.hst / 100) * this.subtotal;

        return Math.round(tax * 100) / 100;
      } else {
        return 0;
      }
    },

    pst() {
      if (!this.localUser) {
        return 0
      }

      if (
        this.taxable &&
        typeof this.localUser?.taxes?.breakdown?.pst !== 'undefined'
      ) {
        let tax = (this.localUser?.taxes?.breakdown?.pst / 100) * this.subtotal;

        return Math.round(tax * 100) / 100;
      } else {
        return 0;
      }
    },

    qst() {
      if (!this.localUser) {
        return 0
      }

      if (
        this.taxable &&
        typeof this.localUser?.taxes?.breakdown?.qst !== 'undefined'
      ) {
        let tax = (this.localUser?.taxes?.breakdown?.qst / 100) * this.subtotal;

        return Math.round(tax * 100) / 100;
      } else {
        return 0;
      }
    }
  },

  watch: {
    type_id() {
      this.calculate();
    },
    subtotal() {
      this.calculate();
    }
  },

  methods: {
    selectUser() {
      if (!this.user_id) {
        this.localUser = null
        return
      }

      this.loading = true
      this.$http
        .get(`/admin/users/${this.user_id}`)
        .then((response) => response.data)
        .then((response) => {
          this.localUser = response
        })
        .finally(() => {
          this.loading = false
        });
    },

    openDialog() {
      this.add_dialog = true;
    },

    calculate() {
      if (!this.selected) {
        return
      }

      if (this.selected.tax === true) this.taxable = true;
      else this.taxable = false;

      this.description =
        this.selected.name +
        ' for ' +
        this.$options.filters.currency(Number(this.total));

      if (this.isRecurring) {
        this.description = `(Recurring) ${this.description}`
      }
    },

    async validateForm() {
      return await this.$validator.validate().then((result) => {
        return result ? true : false;
      });
    },

    async submit() {
      if (!(await this.validateForm())) {
        this.$notify({
          group: 'main',
          title: 'Missing Fields',
          text: this.errs.all().join('. '),
          duration: 5000,
          type: 'error'
        });

        return;
      }

      const payload = {
        user_id: this.user_id,
        subtotal: this.subtotal,
        qst: this.qst,
        pst: this.pst,
        hst: this.hst,
        tax: this.tax,
        total: this.total,
        type_id: this.type_id,
        taxable: this.taxable,
        description: this.description
      }

      if (this.isRecurring) {
        payload.id = this.recurringData?.id
        payload.schedule = this.schedule
        payload.is_active = this.is_active
        this.processRecurring(payload)
      } else {
        this.saveCharges(payload)
      }

    },

    saveCharges(payload) {
      this.setLoading(true)
      this.$http
        .post(`/admin/transactions/create-charge`, payload)
        .then((res) => {
          if (res.data.success) {
            this.successMessage(
              this.$options.filters.currency(Number(this.total)) +
                ' charged to ' +
                this.localUser.email
            );

            this.$emit('complete');

            this.close();
          } else {
            this.errorMessage();
          }
        })
        .finally(() => {
          this.setLoading(false)
        });
    },

    processRecurring(payload) {
      if (payload.id) {
        this.editRecurring(payload)
      } else {
        this.saveRecurring(payload)
      }
    },

    saveRecurring(payload) {
      this.setLoading(true)
      this.$http
        .post(`/admin/recurring-charges`, payload)
        .then((res) => {
          if (res.data.success) {
            this.successMessage(
              'Added ' + this.$options.filters.currency(Number(this.total)) +
                ' recurring charge to ' +
                this.localUser.email
            );

            this.$emit('complete');

            this.close();
          } else {
            this.errorMessage();
          }
        })
        .catch((err) => {
          this.onError(err)
        })
        .finally(() => {
          this.setLoading(false)
        });
    },

    editRecurring(payload) {
      this.setLoading(true)
      this.$http
        .put(`/admin/recurring-charges/${payload.id}`, payload)
        .then((res) => {
          if (res.data.success) {
            this.successMessage('Recurring Charge Successfully Updated')

            this.$emit('complete');

            this.close();
          } else {
            this.errorMessage();
          }
        })
        .catch((err) => {
          this.onError(err)
        })
        .finally(() => {
          this.setLoading(false)
        });
    },

    setLoading(value) {
      this.loading = value
    },

    setChargeData(data) {
      this.recurringData = data
      this.localUser = data?.user || this.user
      this.user_id = this.localUser?.id
      this.subtotal = data?.subtotal || null;
      this.description = data?.description || '';
      this.is_active = !data || data?.is_active ? true : false
      this.type_id = data?.transaction_type_id || null;
      this.taxable = data?.is_taxable || false;

      this.$validator.reset();
    },

    onError(err) {
      let errMsg = 'Unknown Error Occured'
      if (err.response.status == 422) {
        errMsg = err.response.data.message
      }

      this.errorMessage(errMsg);
    },
    close() {
      this.setChargeData()
      this.add_dialog = false;
    }
  }
};
</script>
