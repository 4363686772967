<template>
  <v-container
    class="py-10"
    :fill-height="show_srt"
  >
    <v-row :style="show_sort ? 'height: 90vh;' : ''">
      <v-flex xs12>
        <v-form>
          <v-container>
            <v-row
              class="pb-2"
              style="justify-content: space-between;"
            >
              <v-text-field
                v-model="barcode"
                :label="type.toUpperCase() + ' SCAN'"
                class="mr-2"
                outlined
                clearable
                dense
                hide-details
                @keydown.enter.prevent="manualScan"
              />

              <v-btn
                color="primary"
                @click="manualScan"
              >
                Submit
              </v-btn>
            </v-row>
          </v-container>
          <v-switch
            v-model="show_sort"
            label="Show Sort"
            class="my-0"
          />
        </v-form>
      </v-flex>
      <v-flex
        v-if="show_sort"
        xs12
        style="text-align: center;"
      >
        <v-alert
          v-if="errors.length > 0"
          color="error"
          height="80vh"
          width="100%"
          style="text-align: center; display: flex; justify-content: center; align-items: center;"
        >
          <div
           
            style="font-size: 10vh; font-weight: bold"
          >
            {{ errors[0] }}
          </div>
        </v-alert>

        <v-alert
          v-else-if="message"
          :color="errors.length > 0 ? 'error' : 'success'"
          height="80vh"
          width="100%"
          style="text-align: center; display: flex; justify-content: center; align-items: center;"
        >
          <div style="font-size: 12vh; font-weight: bold">
            {{ message }}
          </div>
        </v-alert>
      </v-flex>

      <v-flex
        v-else
        id="scan-page"
        xs12
      >
        <v-flex
          v-for="(item, index) in errors"
          :key="index"
          xs12
        >
          <v-alert
            class="pa-2"
            :value="true"
            dismissible
            type="error"
            transition="fade-transition"
          >
            {{ item }}
          </v-alert>
        </v-flex>
        <v-flex
          v-for="(item, index) in warnings"
          :key="index"
          xs12
        >
          <v-alert
            class="pa-2"
            :value="true"
            dismissible
            type="warning"
            transition="fade-transition"
          >
            {{ item }}
          </v-alert>
        </v-flex>

        
        <v-data-table
          class="table-striped"
          :headers="headers"
          :items="shipments"
          :loading="loading"
          no-data-text="Awaiting scanned items"
          :page.sync="pagination.page"
          :items-per-page.sync="pagination.itemsPerPage"
          :sort-by.sync="pagination.sortBy"
          :sort-desc.sync="pagination.descending"
        >
          <template #item.tracking="{ item }">
            <div v-if="item.tracking_events.length > 0">
              {{ item.tracking_events[0].status }}
            </div>
          </template>
        </v-data-table>
      </v-flex>
    </v-row>

    <v-dialog
      v-model="showNewUserDialog"
      max-width="500"
    >
      <v-card>
        <v-card-title class="text-h5">
          New User Warning
        </v-card-title>
        <v-card-text>
          New User: Check the contents of the shipment and disable the check_non_fba flag in the user profile.
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            text
            @click="showNewUserDialog = false"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      show_srt: false,
      shipments: [],
      warnings: [],
      activeBtn: 1,
      showNav: true,
      barcodes: [],
      box: 0,
      barcode: null,

      selected: [],
      headers: [
        {
          text: 'Ship Code',
          sortable: false,
          value: 'ship_code'
        },
        {
          text: 'Recipient',
          sortable: false,
          value: 'name'
        },
        {
          text: 'Tracking Number',
          sortable: false,
          value: 'tracking_code'
        },
        {
          text: 'Created At',
          sortable: false,
          value: 'created_at'
        },
        {
          text: 'Updated At',
          sortable: false,
          value: 'updated_at'
        }
        // {
        //   text: 'Tracking',
        //   sortable: false,
        //   value: 'tracking'
        // }
      ],
      pagination: {
        page: 1,
        itemsPerPage: 200,
        sortBy: null,
        descending: false
      },
      loading: false,
      errors: [],
      message: '',
      show_sort: false,
      showNewUserDialog: false
    };
  },
  computed: {},
  created() {
    document.activeElement.blur();
    // Add barcode scan listener and pass the callback function
    this.$barcodeScanner.init(this.onBarcodeScanned);
  },
  destroyed() {
    // Remove listener when component is destroyed
    this.$barcodeScanner.destroy();
  },
  mounted() {
  },
  methods: {
    manualScan() {
      this.onBarcodeScanned(this.barcode);

      this.barcode = null;
    },
    test() {
      var x = document.activeElement.tagName;

      return x;
    },
    // Create callback function to receive barcode when the scanner is already done
    onBarcodeScanned(barcode) {
      // this.$notify({
      //   group: 'main',
      //   title: 'Item Scanned',
      //   text: barcode,
      //   duration: 3000
      // });

      let self = this;

      this.scan(barcode).then(res => {
        var s = res.shipments;
        s.forEach(function(element) {
          self.shipments.push(element);
        });
      });

      // do something...
    },
    // Reset to the last barcode before hitting enter (whatever anything in the input box)
    resetBarcode() {
      this.$barcodeScanner.getPreviousCode();
      // do something...
    },
    scan(barcode) {
      return new Promise((resolve, reject) => {
        this.$http
          .post('/admin/scan/shipments/received', { barcode: barcode, type: this.type })
          .then(response => response.data)
          .then(response => {
            this.message = '';

            if (response.success == true) {
              if((response.audio && this.show_sort) || response.audio == 'fedex express'){
                let utter = new SpeechSynthesisUtterance(response.audio)
                utter.rate = 1.2;
                window.speechSynthesis.speak(utter);
              } else {
                this.playSuccessSound();
              }

              this.message = response.sort_message;

              // Show dialog if new user warning
              if (response.new_user_warning) {
                this.showNewUserDialog = true;
              }
             
              resolve({
                shipments: response.shipments
              });
              if (response.voided > 0) {
                this.playErrorSound();
                this.warnings.push(
                  response.voided + ' shipments have been voided in closeout'
                );
              }
            } else {
              this.playErrorSound();
              if(response.warning){
                this.warnings.push(response.error);
              } else {
                this.errors.push(response.error);
              }
              reject({});
            }
          })
          .catch(err => {
            // catch 422 and display the error message
            if (err.response.status == 422) {
              this.errors = [];
              this.errors.push(err.response.data.message);
            } else {
              this.errors = [];
              this.errors.push('Server error');
            }
            this.playErrorSound();
            console.log(err.response);
            reject({});
          });
      });
    }
  }
};
</script>
