<template>
  <v-row>
    <v-col cols="12">
      <p class="mb-4">
        Configure manufacturer information and manage manufacturers for your shipments.
      </p>
    
      <v-tabs
        v-model="activeTab"
        color="default"
        grow
        class="mb-4"
      >
        <v-tab>Default Settings</v-tab>
        <v-tab>Manage Manufacturers</v-tab>
      </v-tabs>

      <v-tabs-items v-model="activeTab">
        <v-tab-item>
          <!-- Default Manufacturer Settings -->
          <v-card
            outlined
            class="pa-5 mb-5"
          >
            <h3>Manufacturer Information Lock Settings</h3>
            <p class="text-caption mb-4">
              When enabled, the manufacturer information below will be applied to all new shipment items, 
              overriding any product-specific manufacturer information.
            </p>
            
            <v-switch
              v-model="manufacturerSettings.lock_manufacturer_information"
              class="mb-4"
              :label="`${manufacturerSettings.lock_manufacturer_information ? 'Enabled' : 'Disabled'}`"
            />
          </v-card>
          
          <v-form
            ref="form"
            v-model="isFormValid"
            lazy-validation
          >
            <v-card
              outlined
              class="pa-5"
            >
              <h3>Default Manufacturer Information</h3>
              <p class="text-caption mb-4">
                Set the default manufacturer information to be used when the lock setting is enabled.
              </p>

              <v-row>
                <v-col cols="12">
                  <v-autocomplete
                    v-model="manufacturerSettings.default_manufacturer_name"
                    v-validate="{ required: manufacturerSettings.lock_manufacturer_information, max: 100 }"
                    data-vv-name="manufacturer_name"
                    :error-messages="veeErrors.collect('manufacturer_name')"
                    label="Manufacturer Name"
                    :items="manufacturerNames"
                    outlined
                    dense
                    counter="100"
                    clearable
                    auto-select-first
                    :filter="customFilter"
                    :rules="[
                      manufacturerSettings.lock_manufacturer_information ? v => !!v || 'Manufacturer name is required when lock is enabled' : () => true,
                      v => !v || v.length <= 100 || 'Manufacturer name must be less than 100 characters'
                    ]"
                    @change="handleManufacturerNameChange"
                  >
                    <template #no-data>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>
                            No manufacturers found
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-autocomplete>
                </v-col>
                
                <v-col cols="12">
                  <v-text-field
                    v-model="manufacturerSettings.default_manufacturer_address1"
                    v-validate="{ required: manufacturerSettings.lock_manufacturer_information, max: 150 }"
                    data-vv-name="manufacturer_address1"
                    :error-messages="veeErrors.collect('manufacturer_address1')"
                    label="Address"
                    outlined
                    dense
                    counter="150"
                    :rules="[
                      manufacturerSettings.lock_manufacturer_information ? v => !!v || 'Manufacturer address is required when lock is enabled' : () => true,
                      v => !v || v.length <= 150 || 'Address must be less than 150 characters'
                    ]"
                  />
                </v-col>
                
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    v-model="manufacturerSettings.default_manufacturer_city"
                    v-validate="{ required: manufacturerSettings.lock_manufacturer_information, max: 50 }"
                    data-vv-name="manufacturer_city"
                    :error-messages="veeErrors.collect('manufacturer_city')"
                    label="City"
                    outlined
                    dense
                    counter="50"
                    :rules="[
                      manufacturerSettings.lock_manufacturer_information ? v => !!v || 'City is required when lock is enabled' : () => true,
                      v => !v || v.length <= 50 || 'City must be less than 50 characters'
                    ]"
                  />
                </v-col>
                
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    v-model="manufacturerSettings.default_manufacturer_province_code"
                    v-validate="{ required: manufacturerSettings.lock_manufacturer_information, max: 20 }"
                    data-vv-name="manufacturer_province_code"
                    :error-messages="veeErrors.collect('manufacturer_province_code')"
                    label="Province/State Code"
                    outlined
                    dense
                    counter="20"
                    :rules="[
                      manufacturerSettings.lock_manufacturer_information ? v => !!v || 'Province/State code is required when lock is enabled' : () => true,
                      v => !v || v.length <= 20 || 'Province/State code must be less than 20 characters'
                    ]"
                  />
                </v-col>
                
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    v-model="manufacturerSettings.default_manufacturer_postal_code"
                    v-validate="{ required: manufacturerSettings.lock_manufacturer_information, max: 20 }"
                    data-vv-name="manufacturer_postal_code"
                    :error-messages="veeErrors.collect('manufacturer_postal_code')"
                    label="Postal/Zip Code"
                    outlined
                    dense
                    counter="20"
                    :rules="[
                      manufacturerSettings.lock_manufacturer_information ? v => !!v || 'Postal/Zip code is required when lock is enabled' : () => true,
                      v => !v || v.length <= 20 || 'Postal/Zip code must be less than 20 characters'
                    ]"
                  />
                </v-col>
                
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-select
                    v-model="manufacturerSettings.default_manufacturer_country_code"
                    v-validate="{ required: manufacturerSettings.lock_manufacturer_information }"
                    data-vv-name="manufacturer_country_code"
                    :error-messages="veeErrors.collect('manufacturer_country_code')"
                    :items="countryOptions"
                    item-text="name"
                    item-value="value"
                    label="Country"
                    outlined
                    dense
                    :rules="[
                      manufacturerSettings.lock_manufacturer_information ? v => !!v || 'Country is required when lock is enabled' : () => true
                    ]"
                  />
                </v-col>
              </v-row>
            </v-card>
          </v-form>
          
          <v-row class="mt-4">
            <v-col cols="12">
              <v-btn
                color="primary"
                :loading="settingsProcessing"
                :disabled="manufacturerSettings.lock_manufacturer_information && !isFormValid"
                @click="validateSettings"
              >
                Update
              </v-btn>
            </v-col>
          </v-row>
        </v-tab-item>

        <v-tab-item>
          <!-- Manufacturers Table -->
          <v-card
            outlined
            class="pa-5 mb-5"
          >
            <v-row>
              <v-col
                cols="12"
                md="8"
              >
                <h3>Manufacturers</h3>
                <p class="text-caption">
                  Create and manage manufacturers for easy selection in shipment forms.
                </p>
              </v-col>
              <v-col
                cols="12"
                md="4"
                class="text-right"
              >
                <v-btn 
                  color="primary" 
                  :disabled="!canManufacturers"
                  @click="showAddDialog = true"
                >
                  <v-icon left>
                    mdi-plus
                  </v-icon>
                  New
                </v-btn>
              </v-col>
            </v-row>
            
            <v-data-table
              :headers="headers"
              :items="manufacturers"
              :loading="loading"
              :server-items-length="totalManufacturers"
              class="elevation-0 mt-4"
              :footer-props="{
                'items-per-page-options': [10, 25, 50, 100]
              }"
            >
              <template #item.actions="{ item }">
                <v-btn 
                  icon 
                  small 
                  color="primary" 
                  :disabled="!canManufacturers"
                  @click="editManufacturer(item)"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
                <v-btn 
                  icon 
                  small 
                  color="error" 
                  :disabled="!canManufacturers"
                  @click="confirmDelete(item)"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </template>
              
              <template #no-data>
                <p class="text-center pa-4">
                  No manufacturers found. Click "Add Manufacturer" to create one.
                </p>
              </template>
            </v-data-table>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-col>
    
    <!-- Add/Edit Manufacturer Dialog -->
    <v-dialog
      v-model="showAddDialog"
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ isEditing ? 'Edit Manufacturer' : 'Add New Manufacturer' }}</span>
        </v-card-title>
        <v-card-text>
          <v-form
            ref="manufacturerForm"
            v-model="isManufacturerFormValid"
            lazy-validation
          >
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="manufacturerForm.name"
                    :rules="[v => !!v || 'Name is required', v => (v && v.length <= 100) || 'Name must be less than 100 characters']"
                    label="Manufacturer Name*"
                    required
                    outlined
                    dense
                  />
                </v-col>
                
                <v-col cols="12">
                  <v-text-field
                    v-model="manufacturerForm.address1"
                    :rules="[v => !!v || 'Address is required', v => (v && v.length <= 150) || 'Address must be less than 150 characters']"
                    label="Address*"
                    required
                    outlined
                    dense
                  />
                </v-col>
                
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    v-model="manufacturerForm.city"
                    :rules="[v => !!v || 'City is required', v => (v && v.length <= 50) || 'City must be less than 50 characters']"
                    label="City*"
                    required
                    outlined
                    dense
                  />
                </v-col>
                
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    v-model="manufacturerForm.province_code"
                    :rules="[v => !!v || 'Province/State code is required', v => (v && v.length <= 20) || 'Province/State code must be less than 20 characters']"
                    label="Province/State Code*"
                    required
                    outlined
                    dense
                  />
                </v-col>
                
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    v-model="manufacturerForm.postal_code"
                    :rules="[v => !!v || 'Postal/Zip code is required', v => (v && v.length <= 20) || 'Postal/Zip code must be less than 20 characters']"
                    label="Postal/Zip Code*"
                    required
                    outlined
                    dense
                  />
                </v-col>
                
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-select
                    v-model="manufacturerForm.country_code"
                    :rules="[v => !!v || 'Country is required']"
                    :items="countryOptions"
                    item-text="name"
                    item-value="value"
                    label="Country*"
                    required
                    outlined
                    dense
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="grey darken-1"
            text
            @click="closeDialog"
          >
            Cancel
          </v-btn>
          <v-btn 
            color="primary" 
            :loading="tableProcessing"
            :disabled="!isManufacturerFormValid || tableProcessing"
            @click="saveManufacturer"
          >
            {{ isEditing ? 'Update' : 'Add' }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    
    <!-- Confirm Delete Dialog -->
    <v-dialog
      v-model="showDeleteDialog"
      max-width="400px"
    >
      <v-card>
        <v-card-title class="text-h5">
          Confirm Delete
        </v-card-title>
        <v-card-text>
          Are you sure you want to delete the manufacturer <strong>{{ deleteItem?.name }}</strong>? This action cannot be undone.
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="grey darken-1"
            text
            @click="showDeleteDialog = false"
          >
            Cancel
          </v-btn>
          <v-btn 
            color="error" 
            text
            :loading="tableProcessing"
            @click="deleteManufacturer"
          >
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { handleLaravelValidationError } from '@/helpers/helper';

export default {
  name: 'ManufacturerInfo',
  inject: ['$validator'],
  data() {
    return {
      activeTab: 0,
      // Settings tab
      settingsProcessing: false,
      isFormValid: true,
      veeErrors: {},
      manufacturerSettings: {
        lock_manufacturer_information: this.$auth.user.user_config_settings.shipping_defaults.find(
          setting => setting.key === 'lock_manufacturer_information'
        )?.value === '1',
        default_manufacturer_name: this.$auth.user.user_config_settings.shipping_defaults.find(
          setting => setting.key === 'default_manufacturer_name'
        )?.value || '',
        default_manufacturer_address1: this.$auth.user.user_config_settings.shipping_defaults.find(
          setting => setting.key === 'default_manufacturer_address1'
        )?.value || '',
        default_manufacturer_city: this.$auth.user.user_config_settings.shipping_defaults.find(
          setting => setting.key === 'default_manufacturer_city'
        )?.value || '',
        default_manufacturer_province_code: this.$auth.user.user_config_settings.shipping_defaults.find(
          setting => setting.key === 'default_manufacturer_province_code'
        )?.value || '',
        default_manufacturer_postal_code: this.$auth.user.user_config_settings.shipping_defaults.find(
          setting => setting.key === 'default_manufacturer_postal_code'
        )?.value || '',
        default_manufacturer_country_code: this.$auth.user.user_config_settings.shipping_defaults.find(
          setting => setting.key === 'default_manufacturer_country_code'
        )?.value || ''
      },
      
      // Manufacturers table tab
      loading: false,
      tableProcessing: false,
      showAddDialog: false,
      showDeleteDialog: false,
      isEditing: false,
      isManufacturerFormValid: true,
      totalManufacturers: 0,
      manufacturers: [],
      manufacturerForm: this.getEmptyForm(),
      deleteItem: null,
      headers: [
        { text: 'Name', value: 'name' },
        { text: 'Address', value: 'address1' },
        { text: 'City', value: 'city' },
        { text: 'Province/State', value: 'province_code' },
        { text: 'Postal/Zip Code', value: 'postal_code' },
        { text: 'Country', value: 'country_code' },
        { text: 'Actions', value: 'actions', sortable: false, align: 'right' }
      ],
      
      // Shared data
      countryOptions: [],
      manufacturerNames: []
    };
  },
  computed: {
    canManufacturers() {
      return this.can('CLIENT.ACCOUNT_MANAGEMENT');
    }
  },
  watch: {
    'manufacturerSettings.lock_manufacturer_information': function(newVal) {
      if (newVal) {
        // If lock is enabled, validate the form
        this.$nextTick(() => {
          this.$refs.form.validate();
        });
      } else {
        // If lock is disabled, reset validation
        this.$refs.form.resetValidation();
      }
    }
  },
  created() {
    // Initialize country options from config
    this.countryOptions = this.$auth.user.user_config_settings.shipping_defaults.find(
      setting => setting.key === 'default_manufacturer_country_code'
    )?.options || [];
    
    // Initialize VeeValidate errors
    this.veeErrors = this.$validator.errors;
    
    // Load manufacturers for the table and autocomplete
    this.loadManufacturers();
  },
  methods: {
    // ===== Settings Tab Methods =====
    async validateSettings() {
      if (this.manufacturerSettings.lock_manufacturer_information) {
        // Only validate if lock is enabled
        if (this.$refs.form.validate()) {
          await this.validateVeeValidate();
        }
      } else {
        // If lock is disabled, proceed without validation
        this.saveSettings();
      }
    },
    
    async validateVeeValidate() {
      const isValid = await this.$validator.validateAll();
      if (isValid) {
        this.saveSettings();
      } else {
        this.errorMessage('Please correct the validation errors before submitting.');
      }
    },
    
    async saveSettings() {
      this.settingsProcessing = true;
      
      // Convert boolean to string for the backend
      const settings = {
        shipping_defaults: [
          {
            key: 'lock_manufacturer_information',
            value: this.manufacturerSettings.lock_manufacturer_information ? '1' : '0',
            type: 'boolean'
          },
          {
            key: 'default_manufacturer_name',
            value: this.manufacturerSettings.default_manufacturer_name,
            type: 'string'
          },
          {
            key: 'default_manufacturer_address1',
            value: this.manufacturerSettings.default_manufacturer_address1,
            type: 'string'
          },
          {
            key: 'default_manufacturer_city',
            value: this.manufacturerSettings.default_manufacturer_city,
            type: 'string'
          },
          {
            key: 'default_manufacturer_province_code',
            value: this.manufacturerSettings.default_manufacturer_province_code,
            type: 'string'
          },
          {
            key: 'default_manufacturer_postal_code',
            value: this.manufacturerSettings.default_manufacturer_postal_code,
            type: 'string'
          },
          {
            key: 'default_manufacturer_country_code',
            value: this.manufacturerSettings.default_manufacturer_country_code,
            type: 'string'
          }
        ]
      };
      
      try {
        const response = await this.$http.post('/users/updateUserConfigSettings', settings);
        
        if (response.data.success) {
          this.successMessage('Manufacturer settings have been updated');
          
          // Update user settings in auth store
          this.updateAuthUserSettings(settings.shipping_defaults);
        } else {
          this.errorMessage('Manufacturer settings could not be updated');
        }
      } catch (error) {
        handleLaravelValidationError(error);
      } finally {
        this.settingsProcessing = false;
      }
    },
    
    updateAuthUserSettings(shippingDefaults) {
      // Update the user settings in the auth store
      shippingDefaults.forEach(setting => {
        const index = this.$auth.user.user_config_settings.shipping_defaults.findIndex(
          s => s.key === setting.key
        );
        
        if (index !== -1) {
          this.$auth.user.user_config_settings.shipping_defaults[index].value = setting.value;
        }
      });
    },
    
    // Autocomplete filter and handler
    customFilter(item, queryText) {
      return item.toLowerCase().indexOf(queryText.toLowerCase()) > -1;
    },
    
    handleManufacturerNameChange(name) {
      if (!name) return;
      
      // Find the manufacturer with the selected name
      const selectedManufacturer = this.manufacturers.find(m => m.name === name);
      if (selectedManufacturer) {
        // Populate other fields with the selected manufacturer's data
        this.manufacturerSettings.default_manufacturer_address1 = selectedManufacturer.address1;
        this.manufacturerSettings.default_manufacturer_city = selectedManufacturer.city;
        this.manufacturerSettings.default_manufacturer_province_code = selectedManufacturer.province_code;
        this.manufacturerSettings.default_manufacturer_postal_code = selectedManufacturer.postal_code;
        this.manufacturerSettings.default_manufacturer_country_code = selectedManufacturer.country_code;
      }
    },
    
    // ===== Manufacturers Table Methods =====
    getEmptyForm() {
      return {
        id: null,
        name: '',
        address1: '',
        city: '',
        province_code: '',
        postal_code: '',
        country_code: ''
      };
    },
    
    async loadManufacturers() {
      this.loading = true;
      
      try {
        const response = await this.$http.get('/manufacturers');
        
        if (response.data.success) {
          this.manufacturers = response.data.manufacturers;
          this.totalManufacturers = this.manufacturers.length;
          // Extract manufacturer names for autocomplete
          this.manufacturerNames = this.manufacturers.map(m => m.name);
        } else {
          this.errorMessage('Unable to load manufacturers');
        }
      } catch (error) {
        handleLaravelValidationError(error);
      } finally {
        this.loading = false;
      }
    },
    
    editManufacturer(item) {
      this.isEditing = true;
      this.manufacturerForm = { ...item };
      this.showAddDialog = true;
    },
    
    closeDialog() {
      this.showAddDialog = false;
      this.$nextTick(() => {
        this.manufacturerForm = this.getEmptyForm();
        this.isEditing = false;
        if (this.$refs.manufacturerForm) {
          this.$refs.manufacturerForm.resetValidation();
        }
      });
    },
    
    async saveManufacturer() {
      if (!this.$refs.manufacturerForm.validate()) return;
      
      this.tableProcessing = true;
      
      try {
        let response;
        
        if (this.isEditing) {
          // Update existing manufacturer
          const id = this.manufacturerForm.id;
          response = await this.$http.put(`/manufacturers/${id}`, this.manufacturerForm);
        } else {
          // Create new manufacturer
          response = await this.$http.post('/manufacturers', this.manufacturerForm);
        }
        
        if (response.data.success) {
          this.successMessage(
            this.isEditing
              ? 'Manufacturer updated successfully'
              : 'Manufacturer added successfully'
          );
          this.closeDialog();
          this.loadManufacturers();
        } else {
          this.errorMessage(
            this.isEditing
              ? 'Failed to update manufacturer'
              : 'Failed to add manufacturer'
          );
        }
      } catch (error) {
        handleLaravelValidationError(error);
      } finally {
        this.tableProcessing = false;
      }
    },
    
    confirmDelete(item) {
      this.deleteItem = item;
      this.showDeleteDialog = true;
    },
    
    async deleteManufacturer() {
      if (!this.deleteItem) return;
      
      this.tableProcessing = true;
      
      try {
        const response = await this.$http.delete(`/manufacturers/${this.deleteItem.id}`);
        
        if (response.data.success) {
          this.successMessage('Manufacturer deleted successfully');
          this.showDeleteDialog = false;
          this.loadManufacturers();
        } else {
          this.errorMessage('Failed to delete manufacturer');
        }
      } catch (error) {
        handleLaravelValidationError(error);
      } finally {
        this.tableProcessing = false;
        this.deleteItem = null;
      }
    }
  }
};
</script>

<style scoped>
.v-card {
  transition: box-shadow 0.2s ease-in-out;
}
.v-card:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1) !important;
}
</style>