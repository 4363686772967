import axios from 'axios';
import Vue from 'vue';

function initialState() {
  return {
    items: [],
    itemsCount: 0,
    subtotal: 0,
    tax: 0,
    total: 0,
    pickup_location: null
  };
}

export default {
  namespaced: true,
  state: initialState(),
  getters: {
    getCartItems: state => {
      return state.items;
    }
  },
  actions: {
    addProductToCart({ state, commit }, product) {
      console.log(product);
      const cartItem = state.items.find(element => element.id === product.id);
      if (!cartItem) {
        // insert
        commit('addItem', product);
      } else {
        // update
        commit('updateItem', product);
      }

      commit('updateTotals');
    },
    clearCart({ commit }) {
      console.log('clear cart');
      commit('clearAll');
      commit('updateTotals');
    },
    removeProductFromCart({ state, commit }, product) {
      console.log(product);
      const cartItem = state.items.find(element => element.id === product.id);

      console.log('test');
      if (cartItem) {
        // insert
        commit('removeItem', cartItem);
        commit('updateTotals');
      }
    }
  },
  mutations: {
    reset(state) {
      // acquire initial state
      const s = initialState();
      Object.keys(s).forEach(key => {
        state[key] = s[key];
      });
    },
    clearAll(state) {
      state.items = [];
    },
    setCartPickupLocation(state, id){
      state.pickup_location = id;
    },
    recalculateTotals(state){
      // Force totals recalculation when the location changes
      if (state.items.length > 0) {
        this.commit('cart/updateTotals');
      }
    },
    removeItem(state, item) {
      var index = state.items.indexOf(item);

      if (index > -1) {
        state.items.splice(index, 1);

        Vue.notify({
          group: 'main',
          title: 'Item Removed',
          text: `${item.name} has been removed from your cart`,
          duration: 5000
        });
      }
    },
    addItem(state, item) {
      state.items.push(item);
      state.items = state.items.sort((a, b) =>
        a.id > b.id ? 1 : b.id > a.id ? -1 : 0
      );
      Vue.notify({
        group: 'main',
        title: 'Item Added',
        text: `${item.purchase_quantity} x ${
          item.name
        } has been added to your cart`,
        duration: 5000
      });
    },
    updateItem(state, item) {
      var array = [
        ...state.items.filter(element => element.id !== item.id),
        item
      ];

      state.items = array.sort((a, b) =>
        a.id > b.id ? 1 : b.id > a.id ? -1 : 0
      );
    },
    updateTotals(state) {
      axios
        .post('/supplies/price', {
          items: state.items
        })
        .then(response => {
          var price = response.data;

          state.subtotal = price.subtotal;
          state.tax = price.tax;
          state.total = price.total;
        })
        .catch(() => {
          Vue.notify({
            group: 'main',
            title: 'Error',
            text: 'Could not update the supplies price',
            type: 'error',
            duration: 5000
          });
        })
        .finally(() => {
          this.loading = false;
        });

      var count = 0;
      state.items.forEach(function(element) {
        count += parseFloat(element.purchase_quantity);
      });

      state.itemsCount = count;
    }
  }
};
