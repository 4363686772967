<template>
  <div>
    <v-row
      class="my-2"
      dense
    >
      <v-col />
      <v-col cols="auto">
        <v-tooltip bottom>
          <template #activator="{ on }">
            <v-btn
             
              small
              color="primary"
              depressed
              outlined
              v-on="on"
              @click="$refs.returnDetailsImportDialog.openDialog()"
            >
              Import Details
            </v-btn>
          </template>
          <span>Import Return Details</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template #activator="{ on }">
            <v-btn
            
              outlined
              small
              color="primary"
              :loading="isExporting"
              :disabled="!can(PERMISSIONS.SHIPPING)"
              v-on="on"
              @click="exportReturns"
            >
              Export
            </v-btn>
          </template>
          <span>Export Returns</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template #activator="{ on }">
            <v-btn
             
              :disabled="!can(PERMISSIONS.SHIPPING)"
              color="primary"
              small
              outlined
              v-on="on"
              @click="openQuickShipFormDialog"
            >
              Return Label
            </v-btn>
          </template>
          <span>Create Return Label</span>
        </v-tooltip>

        <print-return-label
          v-model="return_label_dialog"
          :disabled="!can(PERMISSIONS.SHIPPING)"
        />

        <quick-ship-form-dialog
          ref="quickShipFormDialog"
          v-model="showQuickShipFormDialog"
          :tax-identifiers="$store.getters['main/tax_identifiers']"
          :update-shipment-handler="updateTable"
        />
      </v-col>
    </v-row>

    <v-layout>
      <v-flex xs12>
        <v-form @submit.prevent>
          <v-container>
            <v-row style="justify-content: space-between">
              <v-col
                cols="12"
                md="12"
                class="pl-0 py-0 pr-1"
              >
                <v-text-field
                  v-model="search"
                  pt-0
                  label="Search"
                  prepend-inner-icon="search"
                  dense
                  outlined
                  clearable
                  hide-details
                  data-lpignore="true"
                  @input="submitSearch()"
                />
              </v-col>
            </v-row>

            <v-row style="justify-content: space-between">
              <v-col
                cols="12"
                md="4"
                class="pl-0 pr-1"
              >
                <v-menu
                  v-model="menu1"
                  :close-on-content-click="false" 
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template #activator="{ on }">
                    <v-text-field
                      v-model="start_date"
                      class="pr-1"
                      style="padding: 0"
                      prepend-inner-icon="event"
                      label="Start Date"
                      readonly
                      clearable
                      dense
                      outlined
                      hide-details
                      v-on="on"
                      @change="updateTable()"
                    />
                  </template>
                  <v-date-picker
                    v-model="start_date"
                    @change="updateTable()"
                    @input="menu1 = false"
                  />
                </v-menu>
              </v-col>

              <v-col
                cols="12"
                md="4"
                class="pl-0 pr-1"
              >
                <v-menu
                  v-model="menu2"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template #activator="{ on }">
                    <v-text-field
                      v-model="end_date"
                      class="pr-1"
                      style="padding: 0"
                      prepend-inner-icon="event"
                      label="End Date"
                      readonly
                      clearable
                      dense
                      outlined
                      hide-details
                      v-on="on"
                      @change="updateTable()"
                    />
                  </template>
                  <v-date-picker
                    v-model="end_date"
                    @change="updateTable()"
                    @input="menu2 = false"
                  />
                </v-menu>
              </v-col>

              <v-col
                cols="12"
                md="4"
                class="pl-0 pr-1"
              >
                <v-select
                  v-model="isDomestic"
                  prepend-inner-icon="mdi-earth"
                  :items="[
                    { value: 1, label: 'Domestic' },
                    { value: 0, label: 'US' }
                  ]"
                  item-value="value"
                  item-text="label"
                  label="Type"
                  dense
                  outlined
                  clearable
                  hide-details
                  @change="updateTable()"
                />
              </v-col>
            </v-row>

            <v-row style="justify-content: space-between">
              <v-col
                cols="12"
                md="12"
                class="pl-0 py-3 pr-1"
              >
                <!-- States -->
                <filter-buttons
                  v-model="filter"
                  :filters="filters"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-flex>
    </v-layout>

    <v-card
      class="elevation-0"
      style="border: 1px solid #e7e7e7"
    >
      <v-data-table
        v-model="selected"
        class="table-striped"
        show-select
        :headers="headers"
        :loading="loading"
        item-key="id"
        :items="items"
        :options.sync="options"
        :server-items-length="totalItems"
        :footer-props="{
          'items-per-page-options': [50, 100, 200, 500]
        }"
        :items-per-page="25"
        @click:row="rowClick"
      >
        <template #item.item="{ item }">
          <div v-if="item.item !== $auth.user?.returns_default_contents">
            {{ item.item }}
          </div>
        </template>
        <template #item.order_id="{ item }">
          <router-link
            v-if="item.order_id"
            :to="{
              name: 'return-invoice',
              params: { id: item.order_id }
            }"
          >
            <v-tooltip bottom>
              <template #activator="{ on }">
                <span v-on="on">{{ item.order_id }}</span>
              </template>
              <span>View Order</span>
            </v-tooltip>
          </router-link>
        </template>

        <template #item.created_at="{ item }">
          {{ item.created_at | date_pretty }}
          <br>
          ({{ item.created_at | datetime_from }})
        </template>


        <!-- <template v-slot:item.status_id="{ item }">
          <div v-html="$options.filters.return_status(item.status_id)"></div>
        </template>

        <template v-slot:item.service_id="{ item }">
          <div v-html="$options.filters.return_service(item.service_id)"></div>
        </template> -->

        <template #item.service_id="{ item }">
          <div v-if="item.service_id === 1 && ![1, 35].includes(item.status_id)">
            <v-btn
              outlined
              small
              color="green"
              :disabled="Boolean($auth.user?.importer_number == null && !(item.type == 'Domestic'))"
              @click="
                showRequestDialog = true;
                selected = [item];
              "
            >
              Request
            </v-btn>
            <v-btn
              outlined
              color="red"
              small
              dark
              @click="
                showDisposeDialog = true;
                selected = [item];
              "
            >
              Dispose
            </v-btn>
          </div>
          <div
            v-else
            v-html="$options.filters.return_service(item.service_id)"
          />
        </template>

        <template #item.status_id="{ item }">
          <div
            v-if="
              item.shipment_id === null &&
                item.status_id === 1 &&
                $auth.user?.return_agree
            "
          >
            <v-menu offset-y>
              <template #activator="{ on, attrs }">
                <v-btn
                  small
                  outlined
                  v-bind="attrs"
                  color="primary"
                  v-on="on"
                  @click="$refs.editReturnDetails.openDialog(item)"
                >
                  Provide Details
                </v-btn>
              </template>
            </v-menu>
            <edit-return-details ref="editReturnDetails" />
          </div>
          <div
            v-else
            v-html="$options.filters.return_status(item.status_id)"
          />
        </template>
      </v-data-table>
      <return-dispose
        v-model="showDisposeDialog"
        :returns="selected"
      />
      <return-request
        v-model="showRequestDialog"
        :returns="selected"
      />
      <return-delete
        v-model="showDeleteDialog"
        :returns="selected"
      />
      <return-details-upload-dialog
        ref="returnDetailsImportDialog"
        @update-returns="updateTable()"
      />
    </v-card>
  </div>
</template>

<script>
import PERMISSIONS from '@/constants/client-permissions';
import ReturnDetailsUploadDialog from './return-details-upload-dialog.vue';
import FilterButtons from '../../shared/FilterButtons.vue';
import QuickShipFormDialog from '../shipments/quick-ship/QuickShipFormDialog.vue';

export default {
  components: {
    ReturnDetailsUploadDialog,
    FilterButtons,
    QuickShipFormDialog
  },
  props: {
    value: {
      type: Array,
      default: () => []
    },
  },
  data() {
    return {
      showQuickShipFormDialog: false,
      filter: null,
      PERMISSIONS: PERMISSIONS,
      showDeleteDialog: false,
      showDisposeDialog: false,
      showRequestDialog: false,
      search: null,
      start_date: null,
      end_date: null,
      loading: false,
      menu1: null,
      menu2: null,
      timer: null,
      isDomestic: null,

      headers: [
        {
          text: 'Name',
          value: 'return_name'
        },
        {
          text: 'Description',
          value: 'item'
        },
        {
          text: 'Service',
          value: 'service_id'
        },
        {
          text: 'Status',
          value: 'status_id'
        },
        {
          text: 'Order #',
          value: 'order_id'
        },
        {
          text: 'Type',
          value: 'type'
        },
        {
          text: 'Created At',
          value: 'created_at'
        }
      ],
      items: [],

      options: {
        sortBy: ['created_at'],
        sortDesc: [true]
      },
      totalItems: 0,
      cancelSource: null,
      isExporting: false,
      return_label_dialog: false,
      filters: [
        {
          icon: 'mdi-package-variant-closed',
          value: null,
          text: 'All'
        },
        {
          icon: 'mdi-comment-account-outline',
          value: 'action-required',
          text: 'Action Required'
        },
        {
          icon: 'mdi-account-question-outline',
          value: 'awaiting-review',
          text: 'Awaiting Review'
        },
        {
          icon: 'mdi-cube-send',
          value: 'in-transit',
          text: 'In-Transit'
        },
        {
          icon: 'mdi-map-marker-check-outline',
          value: 'ready-for-pickup',
          text: 'Ready For Pickup'
        },
        {
          icon: 'mdi-check',
          value: 'complete',
          text: 'Complete'
        },
        {
          icon: 'mdi-pause-circle-outline',
          value: 'hold',
          text: 'Hold'
        }
      ],

    };
  },

  computed: {
    selected: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    }
  },

  watch: {
    filter: function () {
      this.options.page = 1;
      this.updateTable();
    },
    options: {
      handler() {
        this.updateTable();
      },
      deep: true
    },
    'options.itemsPerPage': function (newVal, oldVal) {
      if ((oldVal, newVal)) {
        const preference = {
          table_rows: {
            returns: newVal
          }
        };
        this.$store.dispatch('main/setPreferences', preference);
      }
    }
  },
  mounted() {
    Event.listen('update-returns', () => {
      this.updateTable();
    });

    const table_rows = this.$store.getters['main/table_rows'];
    this.options.itemsPerPage = table_rows?.returns ?? 25;
  },
  methods: {
    openQuickShipFormDialog() {
      this.$refs.quickShipFormDialog.startReturn();
    },
    rowClick(item) {
      this.selected = [item];
    },
    submitSearch() {
      clearTimeout(this.timer);
      var ms = 500;
      var self = this;
      this.options.page = 1;
      this.timer = setTimeout(function () {
        self.updateTable();
      }, ms);
    },
    updateTable() {
      this.selected = [];
      this.totalItems = 0;
      this.items = [];

      this.getDataFromApi().then((data) => {
        this.items = data.items;
        this.totalItems = data.total;
      });
    },

    getDataFromApi() {
      this.loading = true;
      return new Promise((resolve) => {
        let items = [];
        let total = 0;
        let pages = 0;

        if (this.cancelSource) {
          this.cancelSource.cancel('Start new search, stop active search');
        }
        this.cancelSource = axios.CancelToken.source();

        this.$http
          .get(`/returns`, {
            method: 'get',
            params: this.getParams()
          })
          .then((response) => response.data)
          .then((response) => {
            items = response.data;
            total = response.total;
            pages = response.last_page;

            this.loading = false;
            resolve({
              items,
              total,
              pages
            });
          });
      });
    },

    exportReturns() {
      this.isExporting = true;
      this.$http
        .get('returns/export', {
          params: this.getParams()
        })
        .then((response) => {
          this.downloadFileUrl(response.data, `Stallion_Return_Export.csv`, 'text/csv');
        })
        .catch(() => {
          this.errorMessage('Nothing to export');
        })
        .finally(() => {
          this.isExporting = false;
        });
    },

    getParams() {
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      return {
        page: page,
        per_page: itemsPerPage,
        sort_by: sortBy[0],
        desc: sortDesc[0],
        search: this.search,
        start_date: this.start_date,
        end_date: this.end_date,
        filter: this.filter,
        is_domestic: this.isDomestic,
      };
    }
  }
};
</script>

<style scoped>
.v-application p {
  margin-bottom: 0px;
}
</style>
