<template>
  <div>
    <v-container
      class="px-0"
    >
      <v-alert
        v-if="isForceReset"
        type="warning"
        icon="mdi-shield-alert"
        prominent
        dense
        outlined
      >
        {{ passwordSecurity.PASSWORD_BREACH_MESSAGE }}
      </v-alert>

      <v-row>
        <v-col
          cols="12"
          md="12"
        >
          <v-text-field
            v-show="editing"
            v-model="data.current_password"
            v-validate="'required'"
            type="password"
            :dense="dense"
            :error-messages="errs.collect('old_password')"
            data-vv-name="old_password"
            outlined
            label="Old Password"
          />

          <v-text-field
            v-show="editing"
            ref="new_password"
            v-model="data.password"
            v-validate="'required|min:' + passwordSecurity.PASSWORD_MIN_LENGTH + '|verify_password'"
            type="password"
            :dense="dense"
            :error-messages="errs.collect('new_password')"
            data-vv-name="new_password"
            outlined
            label="New Password"
          />

          <v-text-field
            v-show="editing"
            v-model="data.password_confirmation"
            v-validate="'required|confirmed:new_password'"
            type="password"
            :dense="dense"
            :error-messages="errs.collect('password_confirmation')"
            data-vv-name="password_confirmation"
            outlined
            label="Confirm Password"
          />

          <v-btn
            v-if="editing && !isForceReset"
            color="red"
            dark
            @click="reset()"
          >
            Cancel
          </v-btn>

          <v-btn
            v-if="editing"
            color="primary"
            :loading="processing"
            @click="submit()"
          >
            Update
          </v-btn>
          <v-btn
            v-else
            color="primary"
            @click="editing = true"
          >
            Change Password
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import Constants from '@/constants';
export default {
  data() {
    return {
      editing: false,
      data: {
        current_password: null,
        password: null,
        password_confirmation: null
      },
      dense: true,
      processing: false,
      isForceReset: false,
      passwordSecurity: Constants.PASSWORD_SECURITY
    };
  },
  computed: {
    forcePasswordReset() {
      return this.$store.state.auth.force_password_reset;
    }
  },
  mounted() {
    // Check if we're being redirected for a forced password reset
    this.isForceReset = this.$route.query.force_reset === 'true' || this.forcePasswordReset;
    if (this.isForceReset) {
      this.editing = true;
    }
  },
  methods: {
    reset() {
      this.$validator.reset();
      this.data.current_password = null;
      this.data.password = null;
      this.data.password_confirmation = null;
      this.editing = false;
    },
    submit() {
      this.$validator.validate().then(result => {
        if (result) {
          this.processing = true;

          this.data.changedPswrd = false;
          this.$http
            .post('/users/changePassword', this.data)
            .then(response => {
              let res = response.data;
              if (res.success) {
                this.reset();

                swal({
                  title: 'Success',
                  text: Constants.PASSWORD_SECURITY.PASSWORD_RESET_SUCCESS,
                  icon: 'success'
                });
              } else {
                swal({
                  title: 'Error!',
                  text: res.message,
                  icon: 'error'
                });
              }
              this.errors = [];
            })
            .catch(err => {
              swal({
                title: 'Error!',
                text: 'An error occurred',
                icon: 'error'
              });
              if (err.status == 422) {
                this.errors = err.data;
              }
            })
            .finally(() => {
              this.processing = false;
            });
        } else {
          swal({
            title: 'Error',
            text: 'Some of the form fields are invalid.',
            icon: 'error'
          });
        }
      });
    }
  }
};
</script>
