<template>
  <div>
    <v-container fluid>
      <v-card class="mb-4">
        <v-card-title class="headline">
          Regional Supply Pricing
          <v-spacer></v-spacer>
          <v-select
            v-model="selectedRegion"
            :items="regions"
            item-text="name"
            item-value="id"
            label="Select Region"
            dense
            outlined
            class="ml-4"
            style="max-width: 300px"
            @change="loadRegionPricing"
          ></v-select>
        </v-card-title>
        
        <v-card-text>
          <p>
            Manage region-specific pricing for supplies. When no regional price is set, 
            the system will use the default price from the supplies table.
          </p>
          
          <v-data-table
            :headers="headers"
            :items="filteredSupplies"
            :search="search"
            :loading="loading"
            sort-by="name"
            class="elevation-1"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                  class="mb-2"
                ></v-text-field>
                <v-spacer></v-spacer>
                <v-switch 
                  v-model="showOnlyOverridden"
                  label="Show only overridden prices"
                  class="mt-4 mr-4"
                  hide-details
                ></v-switch>
                <v-btn
                  color="primary"
                  dark
                  class="mb-2"
                  @click="saveAllChanges"
                  :disabled="!hasChanges"
                >
                  Save All Changes
                </v-btn>
              </v-toolbar>
            </template>

            <!-- Name column -->
            <template v-slot:item.name="{ item }">
              {{ item.name }}
            </template>

            <!-- Default Price column -->
            <template v-slot:item.price="{ item }">
              <span :class="{ 'grey--text': item.is_override }">
                {{ formatCurrency(item.price) }}
              </span>
            </template>

            <!-- Override Price column -->
            <template v-slot:item.override_price="{ item }">
              <v-text-field
                v-model="item.override_price"
                type="number"
                step="0.01"
                min="0"
                :placeholder="formatCurrency(item.price)"
                dense
                hide-details
                @input="updateSupplyPrice(item)"
                :class="{ 'price-override-active': item.is_override }"
              ></v-text-field>
            </template>

            <!-- Status column -->
            <template v-slot:item.status="{ item }">
              <v-chip
                :color="getStatusColor(item)"
                small
                :title="getStatusTooltip(item)"
              >
                {{ getStatusText(item) }}
              </v-chip>
            </template>

            <!-- Enabled column -->
            <template v-slot:item.override_enabled="{ item }">
              <v-switch
                v-model="item.override_enabled"
                :disabled="!item.is_override"
                @change="updateSupplyEnabled(item)"
                hide-details
                dense
              ></v-switch>
            </template>

            <!-- Actions column -->
            <template v-slot:item.actions="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-if="item.is_override"
                    icon
                    small
                    color="error"
                    v-bind="attrs"
                    v-on="on"
                    @click="confirmDeletePriceOverride(item)"
                  >
                    <v-icon small>mdi-delete</v-icon>
                  </v-btn>
                </template>
                <span>Remove price override</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    small
                    color="primary"
                    v-bind="attrs"
                    v-on="on"
                    @click="savePriceOverride(item)"
                    :disabled="!isItemChanged(item)"
                  >
                    <v-icon small>mdi-content-save</v-icon>
                  </v-btn>
                </template>
                <span>Save price override</span>
              </v-tooltip>
            </template>

            <!-- No data state -->
            <template v-slot:no-data>
              <v-alert type="info" class="ma-4">
                No supply pricing data available for this region.
              </v-alert>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-container>

    <!-- Confirmation Dialog -->
    <v-dialog v-model="confirmDialog" max-width="500px">
      <v-card>
        <v-card-title class="headline">Confirm Delete</v-card-title>
        <v-card-text>
          Are you sure you want to remove the price override for <strong>{{ confirmItem ? confirmItem.name : '' }}</strong>?
          This will revert to using the default price of {{ confirmItem ? formatCurrency(confirmItem.price) : '' }}.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="confirmDialog = false">Cancel</v-btn>
          <v-btn color="error" text @click="deletePriceOverride">Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Snackbar for notifications -->
    <v-snackbar
      v-model="snackbar.show"
      :color="snackbar.color"
      :timeout="snackbar.timeout"
    >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="snackbar.show = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  name: 'SuppliesPricing',
  
  data() {
    return {
      regions: [],
      selectedRegion: null,
      suppliesData: [],
      originalSuppliesData: [],
      search: '',
      loading: false,
      showOnlyOverridden: false,
      headers: [
        { text: 'Supply Name', value: 'name', sortable: true },
        { text: 'Default Price', value: 'price', sortable: true, width: '150px' },
        { text: 'Region Price', value: 'override_price', sortable: false, width: '200px' },
        { text: 'Status', value: 'status', sortable: false, width: '120px' },
        { text: 'Enabled', value: 'override_enabled', sortable: false, width: '100px' },
        { text: 'Actions', value: 'actions', sortable: false, width: '120px' },
      ],
      confirmDialog: false,
      confirmItem: null,
      snackbar: {
        show: false,
        text: '',
        color: 'success',
        timeout: 3000,
      },
      changedItems: new Set(),
    }
  },
  
  computed: {
    filteredSupplies() {
      if (this.showOnlyOverridden) {
        return this.suppliesData.filter(item => item.is_override);
      }
      return this.suppliesData;
    },
    
    hasChanges() {
      return this.changedItems.size > 0;
    }
  },
  
  mounted() {
    this.loadRegions();
  },
  
  methods: {
    // Load all regions that can have price overrides
    loadRegions() {
      this.loading = true;
      console.log('Loading regions...');
      this.$http.get('/admin/supplies/pricing/regions')
        .then(response => {
          console.log('Regions loaded:', response.data);
          this.regions = response.data.regions;
          
          if (this.regions.length > 0 && !this.selectedRegion) {
            this.selectedRegion = this.regions[0].id;
            console.log('Selected region ID:', this.selectedRegion);
            this.loadRegionPricing();
          }
        })
        .catch(error => {
          this.showSnackbar('Failed to load regions', 'error');
          console.error('Error loading regions:', error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    
    // Load pricing data for a selected region
    loadRegionPricing() {
      if (!this.selectedRegion) return;
      
      this.loading = true;
      this.changedItems.clear();
      
      console.log('Loading pricing data for region ID:', this.selectedRegion);
      
      this.$http.get(`/admin/supplies/pricing/${this.selectedRegion}`)
        .then(response => {
          console.log('Pricing data loaded:', response.data);
          
          if (!response.data.supplies || response.data.supplies.length === 0) {
            console.warn('No supplies data returned for region:', this.selectedRegion);
          }
          
          this.suppliesData = response.data.supplies.map(item => {
            // Convert string price to number for the input field
            if (item.override_price !== null) {
              item.override_price = parseFloat(item.override_price);
            }
            return item;
          });
          
          console.log('Processed supplies data:', this.suppliesData.length, 'items');
          console.log('Sample item:', this.suppliesData.length > 0 ? this.suppliesData[0] : 'No items');
          
          // Make a deep copy for comparison to track changes
          this.originalSuppliesData = JSON.parse(JSON.stringify(this.suppliesData));
        })
        .catch(error => {
          this.showSnackbar('Failed to load pricing data', 'error');
          console.error('Error loading pricing data:', error);
          console.error('Error details:', error.response ? error.response.data : 'No response data');
        })
        .finally(() => {
          this.loading = false;
        });
    },
    
    // Format a number as currency
    formatCurrency(value) {
      if (value === null || value === undefined) return '$0.00';
      
      return '$' + parseFloat(value).toFixed(2);
    },
    
    // Get status color based on whether price is overridden
    getStatusColor(item) {
      if (!item.is_override) return 'grey';
      if (parseFloat(item.override_price) > parseFloat(item.price)) return 'amber';
      if (parseFloat(item.override_price) < parseFloat(item.price)) return 'green';
      return 'blue';
    },
    
    // Get status text based on whether price is overridden
    getStatusText(item) {
      if (!item.is_override) return 'Default';
      if (parseFloat(item.override_price) > parseFloat(item.price)) return 'Higher';
      if (parseFloat(item.override_price) < parseFloat(item.price)) return 'Lower';
      return 'Same';
    },
    
    // Get tooltip text for status
    getStatusTooltip(item) {
      if (!item.is_override) return 'Using default price';
      if (parseFloat(item.override_price) > parseFloat(item.price)) {
        return `Regional price is higher by ${this.formatCurrency(item.override_price - item.price)}`;
      }
      if (parseFloat(item.override_price) < parseFloat(item.price)) {
        return `Regional price is lower by ${this.formatCurrency(item.price - item.override_price)}`;
      }
      return 'Regional price is the same as default';
    },
    
    // Track changes when a supply's price is updated
    updateSupplyPrice(item) {
      // If the price is empty, null, or the same as the original, don't track as a change
      let originalItem = this.originalSuppliesData.find(i => i.id === item.id);
      
      if (!item.override_price) {
        item.override_price = null;
      }
      
      if (this.isItemChanged(item)) {
        this.changedItems.add(item.id);
      } else {
        this.changedItems.delete(item.id);
      }
    },
    
    // Track changes when a supply's enabled status is updated
    updateSupplyEnabled(item) {
      if (this.isItemChanged(item)) {
        this.changedItems.add(item.id);
      } else {
        this.changedItems.delete(item.id);
      }
    },
    
    // Check if an item has been changed from its original state
    isItemChanged(item) {
      const originalItem = this.originalSuppliesData.find(i => i.id === item.id);
      if (!originalItem) return true;
      
      // If the price was null (default) and is now set, or vice versa, it's changed
      const priceChanged = 
        (originalItem.override_price === null && item.override_price !== null) ||
        (originalItem.override_price !== null && item.override_price === null) ||
        (originalItem.override_price !== null && item.override_price !== null && 
          parseFloat(originalItem.override_price) !== parseFloat(item.override_price));
      
      // If enabled status changed
      const enabledChanged = originalItem.override_enabled !== item.override_enabled;
      
      return priceChanged || enabledChanged;
    },
    
    // Open confirmation dialog for deleting a price override
    confirmDeletePriceOverride(item) {
      this.confirmItem = item;
      this.confirmDialog = true;
    },
    
    // Delete a price override
    deletePriceOverride() {
      if (!this.confirmItem) return;
      
      this.loading = true;
      
      this.$http.post('/admin/supplies/pricing/delete', {
        region_id: this.selectedRegion,
        supply_id: this.confirmItem.id,
      })
        .then(() => {
          // Update local data
          const index = this.suppliesData.findIndex(i => i.id === this.confirmItem.id);
          if (index !== -1) {
            this.suppliesData[index].is_override = false;
            this.suppliesData[index].override_price = null;
            this.suppliesData[index].override_enabled = true;
            
            // Update original data to match
            const originalIndex = this.originalSuppliesData.findIndex(i => i.id === this.confirmItem.id);
            if (originalIndex !== -1) {
              this.originalSuppliesData[originalIndex].is_override = false;
              this.originalSuppliesData[originalIndex].override_price = null;
              this.originalSuppliesData[originalIndex].override_enabled = true;
            }
            
            // Remove from changed items
            this.changedItems.delete(this.confirmItem.id);
          }
          
          this.showSnackbar('Price override removed successfully');
        })
        .catch(error => {
          this.showSnackbar('Failed to remove price override', 'error');
          console.error('Error removing price override:', error);
        })
        .finally(() => {
          this.loading = false;
          this.confirmDialog = false;
          this.confirmItem = null;
        });
    },
    
    // Save a single price override
    savePriceOverride(item) {
      this.loading = true;
      
      // Determine if this is a new price override
      const isNewOverride = !item.is_override;
      
      const data = {
        region_id: this.selectedRegion,
        supply_id: item.id,
        price: item.override_price,
        // For new overrides, explicitly set enabled to true
        // For existing overrides, use the current enabled value
        enabled: isNewOverride ? true : item.override_enabled,
      };
      
      this.$http.post('/admin/supplies/pricing/update', data)
        .then(() => {
          // Update local data to reflect the saved state
          const index = this.suppliesData.findIndex(i => i.id === item.id);
          if (index !== -1) {
            this.suppliesData[index].is_override = true;
            
            // For new overrides, make sure UI reflects that it's enabled
            if (isNewOverride) {
              this.suppliesData[index].override_enabled = true;
            }
            
            // Update original data to match the new saved state
            const originalIndex = this.originalSuppliesData.findIndex(i => i.id === item.id);
            if (originalIndex !== -1) {
              this.originalSuppliesData[originalIndex].is_override = true;
              this.originalSuppliesData[originalIndex].override_price = item.override_price;
              this.originalSuppliesData[originalIndex].override_enabled = isNewOverride ? true : item.override_enabled;
            }
            
            // Remove from changed items since it's now saved
            this.changedItems.delete(item.id);
          }
          
          this.showSnackbar('Price override saved successfully');
        })
        .catch(error => {
          this.showSnackbar('Failed to save price override', 'error');
          console.error('Error saving price override:', error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    
    // Save all changed price overrides
    saveAllChanges() {
      if (this.changedItems.size === 0) return;
      
      this.loading = true;
      
      const supplies = this.suppliesData
        .filter(item => this.changedItems.has(item.id))
        .map(item => {
          // Determine if this is a new price override
          const isNewOverride = !item.is_override;
          
          return {
            supply_id: item.id,
            price: item.override_price || item.price, // If override_price is null, use the default
            // For new overrides, explicitly set enabled to true
            enabled: isNewOverride ? true : item.override_enabled,
          };
        });
      
      this.$http.post('/admin/supplies/pricing/bulk-update', {
        region_id: this.selectedRegion,
        supplies,
      })
        .then(() => {
          // Update original data and is_override flag for all changed items
          for (const id of this.changedItems) {
            const item = this.suppliesData.find(i => i.id === id);
            const originalItem = this.originalSuppliesData.find(i => i.id === id);
            
            if (item && originalItem) {
              // If this was a new override, ensure it's marked as enabled
              const isNewOverride = !item.is_override;
              if (isNewOverride) {
                item.override_enabled = true;
              }
              
              item.is_override = true;
              originalItem.is_override = true;
              originalItem.override_price = item.override_price;
              originalItem.override_enabled = isNewOverride ? true : item.override_enabled;
            }
          }
          
          // Clear the changed items set
          this.changedItems.clear();
          
          this.showSnackbar('All price overrides saved successfully');
        })
        .catch(error => {
          this.showSnackbar('Failed to save price overrides', 'error');
          console.error('Error saving price overrides:', error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    
    // Show a snackbar notification
    showSnackbar(text, color = 'success') {
      this.snackbar.text = text;
      this.snackbar.color = color;
      this.snackbar.show = true;
    },
  },
  
  watch: {
    showOnlyOverridden(val) {
      // Reset search when toggle changes
      this.search = '';
    }
  },
}
</script>

<style scoped>
.price-override-active {
  background-color: rgba(0, 200, 83, 0.1);
  border-radius: 4px;
}

.v-data-table ::v-deep .v-input__slot {
  min-height: 32px !important;
}

.v-data-table ::v-deep .v-input--switch {
  margin-top: 0 !important;
}
</style>