<template>
  <v-row>
    <v-col cols="12">
      <user-settings-option
        v-for="(list, index) in settings"
        :key="index"
        :list="list"
      />
    </v-col>
    <v-col cols="12">
      <v-btn
        color="primary"
        :loading="processing"
        :disabled="settings.length == 0"
        @click="submit"
      >
        Update
      </v-btn>
    </v-col>
  </v-row>
</template>
<script>
export default {
  props: {
    settings: {
      type: Array,
      default: () => {
        [];
      }
    }
  },
  data() {
    return {
      processing: false
    };
  },
  mounted() {
    // Parse any JSON string array values to actual arrays
    this.settings.forEach(setting => {
      if (setting.type === 'array' && typeof setting.value === 'string' && setting.value) {
        try {
          const parsedValue = JSON.parse(setting.value);
          if (Array.isArray(parsedValue)) {
            setting.value = parsedValue;
          }
        } catch (e) {
          // Silent error - just use empty array
          setting.value = [];
        }
      }
    });
  },
  methods: {
    prepareSettingsForSave() {
      // Make a deep copy to avoid modifying the original settings
      const preparedSettings = JSON.parse(JSON.stringify(this.settings));
      
      // Create a new settings array with the right format
      const formattedSettings = [];
      
      preparedSettings.forEach(setting => {
        // Create a new cleaned copy of the setting
        const newSetting = { ...setting };
        
        // For array types
        if (setting.type === 'array') {
          // Convert value to array first, regardless of current format
          let cleanArray;
          
          // If it's already a string (JSON), parse it
          if (typeof setting.value === 'string' && setting.value.trim() !== '') {
            try {
              const parsed = JSON.parse(setting.value);
              cleanArray = Array.isArray(parsed) ? parsed : [];
            } catch (e) {
              cleanArray = [];
            }
          }
          // If it's already an array, use it
          else if (Array.isArray(setting.value)) {
            cleanArray = setting.value;
          }
          // Default to empty array
          else {
            cleanArray = [];
          }
          
          // Clean the array
          cleanArray = cleanArray
            .filter(v => v !== undefined && v !== null)
            .map(v => Number(v));
            
          // Now convert to string for submission
          newSetting.value = JSON.stringify(cleanArray);
        }
        
        // For boolean types, convert to 1 or 0 (the system expects integers)
        if (setting.type === 'boolean') {
          newSetting.value = setting.value ? 1 : 0;
        }
        
        formattedSettings.push(newSetting);
      });
      
      return formattedSettings;
    },
    
    submit() {
      this.processing = true;
      
      // Prepare data to match the format the system expects
      const preparedData = this.prepareSettingsForSave();
      
      this.$http
        .post('/admin/system-settings', {
          data: preparedData
        })
        .then(() => {
          this.successMessage('FBA settings have been saved');
        })
        .catch(() => {
          this.errorMessage('Failed to save FBA settings');
        })
        .finally(() => {
          this.processing = false;
        });
    }
  }
};
</script>