<template>
  <v-main>
    <two-factor-challenge-dialog
      v-model="show2faChallengeDialog"
      :remember-me="rememberMe"
      @onSuccess="onLoginSuccess"
    />

    <v-row
      container--fluid
      fill-height
    >
      <v-col
        cols="12"
        md="4"
        class="image-column"
        style="background-color: #f9ac40; height: 100vh"
      >
        <v-container>
          <v-row>
            <v-col
              cols="12"
              class="px-10 mb-12"
            >
              <a href="https://stallionexpress.ca">
                <img
                  class="ml-0 img-responsive"
                  src="@/images/StallionExpress-logo-white.png"
                  style="width: 150px"
                >
              </a>
            </v-col>
            <v-col
              cols="12"
              class="px-10 py-0 stallion-title"
              style="text-align: left"
            >
              <h1
                class="font-weight-bold"
                style="color: white; font-size: 28px"
              >
                Ship Faster, Smarter, and Cheaper with Stallion!
              </h1>
            </v-col>
            <v-col
              cols="12"
              class="px-10 py-0"
            >
              <img
                class="img-responsive login-image"
                src="@/images/login-graphic.png"
                style="width: 285px"
              >
            </v-col>
          </v-row>
        </v-container>
      </v-col>

      <v-col
        cols="12"
        md="8"
        style="height: 100vh"
      >
        <v-card class="elevation-0 py-5 px-3">
          <v-container>
            <v-row>
              <v-col v-if="isShopify">
                <img
                  style="width: 200px"
                  src="https://cdn.shopify.com/shopifycloud/brochure/assets/brand-assets/shopify-logo-primary-logo-456baa801ee66a0a435671082365958316831c9960c480451dd0330bcdae304f.svg"
                >
              </v-col>
              <v-col
                cols="12"
                class="text-right"
              >
                <div>
                  <p>
                    Need Help?
                    <a
                      style="font-weight: 700; text-decoration: none"
                      target="_blank"
                      @click="handleClick"
                    >Contact
                      Us</a>
                  </p>
                </div>
              </v-col>
              <v-col
                cols="12"
                class="pb-12 text-right"
              >
                <div class="float-right">
                  <weglot-switcher />
                </div>
              </v-col>

              <v-col cols="12">
                <h2 class="font-weight-bold">
                  Sign in to Stallion Express
                </h2>
                <p>Please enter your credentials to proceed.</p>
              </v-col>
            </v-row>
          </v-container>

          <v-form @submit.prevent>
            <v-container>
              <v-row>
                <v-container class="py-0">
                  <v-alert
                    color="error"
                    class="login-field"
                    dark
                    :value="error != ''"
                    icon="warning"
                    transition="fade-transition"
                  >
                    {{ error }}
                  </v-alert>
                </v-container>
                <v-container>
                  <v-text-field
                    v-model="username"
                    v-validate="'required'"
                    autofocus
                    prepend-inner-icon="person"
                    data-vv-name="username"
                    :error-messages="errs.collect('username')"
                    name="username"
                    label="Username/Email"
                    type="text"
                    data-vv-validate-on="blur"
                    dusk="email-input"
                    class="login-field"
                  />
                </v-container>
                <v-container class="py-0">
                  <v-text-field
                    id="password"
                    ref="password"
                    v-model="password"
                    v-validate="'required'"
                    prepend-inner-icon="lock"
                    data-vv-name="password"
                    :error-messages="errs.collect('password')"
                    name="password"
                    label="Password"
                    type="password"
                    data-vv-validate-on="blur"
                    class="login-field"
                  />
                </v-container>
                <v-container class="py-0 ml-0 login-field">
                  <v-layout>
                    <v-flex xs6>
                      <v-checkbox
                        id="remember"
                        v-model="rememberMe"
                        ma-0
                        label="Remember me"
                        :value="true"
                      />
                    </v-flex>
                    <v-flex xs6>
                      <router-link
                        id="to-recover"
                        style="float: right; margin-top: 20px"
                        to="/forgot-password"
                      >
                        Forgot Password
                      </router-link>
                    </v-flex>
                  </v-layout>
                </v-container>
                <v-container>
                  <v-layout align-center>
                    <v-flex
                      xs12
                      text-left
                    >
                      <v-btn
                        :loading="loading"
                        :disabled="loading"
                        color="primary"
                        type="submit"
                        class="px-12"
                        name="login-button"
                        @click="submit()"
                      >
                        Log In
                      </v-btn>
                    </v-flex>
                  </v-layout>
                </v-container>
                <v-container>
                  <v-layout
                    align-center
                    class="pt-12"
                  >
                    <v-flex
                      xs12
                      text-left
                    >
                      <p>
                        Don't have an account?
                        <router-link
                          style="font-weight: 700; text-decoration: none"
                          to="/register"
                          aria-expanded="false"
                        >
                          Register
                        </router-link>
                      </p>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-row>
            </v-container>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </v-main>
</template>

<script>
import TwoFactorChallengeDialog from './two-factor-challenge-dialog.vue';
import Constants from '@/constants';
export default {
  metaInfo: {
    title: "Login | Stallion Express"
  },
  components: {
    TwoFactorChallengeDialog
  },
  data() {
    return {
      username: '',
      password: '',
      email: '',
      rememberMe: false,
      error: '',
      loading: false,
      show2faChallengeDialog: false,
      isShopify: false
    };
  },
  mounted() {
    if (this.$route.query.host && this.$route.query.shop) {
      this.isShopify = true;
      swal({
        title: 'Login Required',
        text: `Login to finishing adding your Shopify store ${this.$route.query.shop} to your Stallion Express account.`,
        icon: 'info'
      });
    }
  },

  methods: {
    submit() {
      this.error = '';
      this.$validator.validate().then((result) => {
        if (result) {
          this.loading = true;

          this.login(this.username, this.password, this.rememberMe, '/auth/login', this.isShopify ? '/import/shopify' : '/')
            .catch((res) => {
              this.onLoginError(res);
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
    onLoginSuccess() {
      // Check if password reset is required
      if (this.$route.query.force_reset || this.$store.state.auth.force_password_reset) {
        swal({
          title: Constants.PASSWORD_SECURITY.PASSWORD_BREACH_TITLE,
          text: Constants.PASSWORD_SECURITY.PASSWORD_BREACH_MESSAGE,
          icon: 'warning',
          buttons: {
            reset: {
              text: "Reset Password",
              value: true,
            },
          },
          closeOnClickOutside: false,
          closeOnEsc: false,
        }).then((value) => {
          if (value) {
            this.$router.push('/settings/password');
          }
        });
      }
    },
    onLoginError(res) {
      // this.loading = false;
      if (
        res.response &&
        res.response.status === 400 &&
        res.response.data.two_factor === true
      ) {
        this.show2faChallengeDialog = true;
      } else if (res.response && res.response.status === 429) {
        try {
          this.error = res.response.data.errors.username[0];
        } catch (err) {
          this.error = 'Too many login attempts';
        }
      } else if (
        res.response.status === 422 &&
        typeof res.response.data.errors !== 'undefined'
      ) {
        const errors = res.response.data.errors;
        
        // Check if this is a password breach error
        if (
          errors.username && 
          errors.username[0] === Constants.PASSWORD_SECURITY.PASSWORD_BREACH_MESSAGE
        ) {
          // Redirect to password reset page with username pre-populated
          this.$router.push({ 
            path: '/forgot-password', 
            query: { username: this.username } 
          });
          return;
        }
        
        let message = '';
        for (var field in errors) {
          message += `${errors[field][0]}\n`;
        }

        this.errorMessage(message);
      } else {
        this.error = 'Invalid Credentials.';
        this.password = '';
        this.$refs.password.$el.focus();
      }
    },
    handleClick() {
      window.open(this.$contactUs, '_blank')
    }
  }
};
</script>

<style>
#remember .v-input--selection-controls {
  margin-top: 16px;
  padding-top: 4px;
}

.v-application p {
  margin-bottom: 0px !important;
}

@media only screen and (min-width: 1440px) {
  .login-image {
    width: 400px !important;
  }

  .contact-us {
    margin-bottom: 100px !important;
  }

  .stallion-title {
    margin-bottom: 50px !important;
  }
}

.login-field {
  max-width: 400px !important;
}

@media only screen and (max-width: 960px) {
  .login-image {
    display: none !important;
  }

  .image-column {
    height: 30vh !important;
  }
}
</style>
