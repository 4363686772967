<template>
  <v-container
    v-if="shipmentLocal"
    class="pa-0 shipment-details"
    style="margin-bottom: 400px;"
  >
    <v-row dense>
      <v-col
        cols="12"
        class="px-1"
      >
        <v-btn
          color="primary"
          :disabled="updateLoading || purchaseLoading"
          small
          outlined
          hide-details
          @click="save()"
        >
          <v-icon
            small
            left
          >
            mdi-content-save
          </v-icon>
          Save
        </v-btn>
        <v-btn
          v-show="order.original_order_hash != null"
          color="primary"
          :disabled="updateLoading || purchaseLoading"
          small
          outlined
          hide-details
          @click="undoSplit()"
        >
          <v-icon
            big
            left
          >
            undo
          </v-icon>
          Undo Split
        </v-btn>
        <v-btn
          v-show="order.merged_order == true"
          color="primary"
          :disabled="updateLoading || purchaseLoading"
          small
          outlined
          hide-details
          @click="unmergeOrders()"
        >
          <v-icon
            big
            left
          >
            mdi-call-split
          </v-icon>
          Unmerge
        </v-btn>
        <a
          class="float-right"
          target="_blank"
          @click="openPresetsDialog()"
        >Apply Preset</a>
        <!-- <v-btn
          class="float-right"
          color="primary"    
          :disabled="updateLoading || purchaseLoading"
          small
          @click="save()"
          v-on="on"
        >
          <v-icon
            small
            left
          >
            mdi-content-save
          </v-icon>
          Save
        </v-btn> -->
      </v-col>
      <v-col
        v-if="shipmentLocal.error"
        cols="12"
      >
        <error-details :shipment="shipmentLocal" />
      </v-col>
      <v-col cols="12">
        <v-expansion-panels
          v-model="selectedPanel"
          class="edit-order-expansion-panel"
          focusable
          flat
          accordion
        >
          <v-expansion-panel :key="0">
            <v-expansion-panel-header
              disable-icon-rotate
              expand-icon="mdi-package-variant-closed"
            >
              Shipping
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row dense>
                <v-col cols="6">
                  <v-text-field
                    v-model="shipmentLocal.weight"
                    v-validate="'required|min_value:0.001'"
                    :hint="isScaleInitialized() ? 'Read Scale (Ctrl + m)' : ''"
                    :error-messages="errs.collect('weight')"
                    data-vv-name="weight"
                    label="Weight"
                    dense
                    outlined
                    hide-details
                    type="number"
                  >
                    <template
                      v-if="isScaleInitialized()"
                      #append-outer
                    >
                      <AutoScaleButton @weight="setWeight" />
                    </template>
                  </v-text-field>
                </v-col>

                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                >
                  <v-select
                    v-model="shipmentLocal.weight_unit"
                    v-validate="'required'"
                    :error-messages="errs.collect('weight_unit')"
                    data-vv-name="weight_unit"
                    :items="['kg', 'g', 'oz', 'lbs']"
                    dense
                    outlined
                    hide-details
                  />
                </v-col>

                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                >
                  <v-select
                    v-model="selectedBoxId"
                    :items="predefinedBoxes"
                    item-text="name"
                    item-value="id"
                    label="Apply Box (Optional)"
                    dense
                    outlined
                    hide-details
                    clearable
                    @change="applyBox"
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="12"
                >
                  <v-select
                    v-model="shipmentLocal.package_type_id"
                    v-validate="'required'"
                    :error-messages="errs.collect('package_type')"
                    data-vv-name="package_type"
                    label="Package Type"
                    :items="packageArray"
                    item-text="description"
                    item-value="id"
                    dense
                    outlined
                    hide-details
                  />
                </v-col>
                
                <v-col
                  v-if="[19, 20].includes(shipmentLocal.package_type_id)"
                  cols="12"
                  sm="6"
                  md="3"
                >
                  <v-text-field
                    v-model="shipmentLocal.length"
                    v-validate="dimensionsRequired ? 'required' : null"
                    :error-messages="errs.collect('length')"
                    data-vv-name="length"
                    label="L"
                    dense
                    outlined
                    hide-details
                    type="number"
                  />
                </v-col>

                <v-col
                  v-if="[19, 20].includes(shipmentLocal.package_type_id)"
                  cols="12"
                  sm="6"
                  md="3"
                >
                  <v-text-field
                    v-model="shipmentLocal.width"
                    v-validate="dimensionsRequired ? 'required' : null"
                    :error-messages="errs.collect('width')"
                    data-vv-name="width"
                    label="W"
                    dense
                    outlined
                    hide-details
                    type="number"
                  />
                </v-col>

                <v-col
                  v-if="[19, 20].includes(shipmentLocal.package_type_id)"
                  cols="12"
                  sm="6"
                  md="3"
                >
                  <v-text-field
                    v-model="shipmentLocal.height"
                    v-validate="dimensionsRequired ? 'required' : null"
                    :error-messages="errs.collect('height')"
                    data-vv-name="height"
                    label="H"
                    dense
                    outlined
                    hide-details
                    type="number"
                  />
                </v-col>

                <v-col
                  v-if="[19, 20].includes(shipmentLocal.package_type_id)"
                  cols="12"
                  sm="6"
                  md="3"
                >
                  <v-select
                    v-model="shipmentLocal.size_unit"
                    v-validate="dimensionsRequired ? 'required' : null"
                    :error-messages="errs.collect('size_unit')"
                    data-vv-name="size_unit"
                    :items="['cm', 'in']"
                    dense
                    outlined
                    hide-details
                  />
                </v-col>

                <!-- <v-col cols="12">
                  <v-text-field
                    v-model="shipmentLocal.package_contents"
                    v-validate="'required|max:255'"
                    
                    :error-messages="errs.collect('description')"
                    data-vv-name="description"
         
                    label="Description"
                    dense
                    outlined hide-details 
                  />
                </v-col> -->

                <!-- <v-col
                  cols="12"
                  sm="6"
                  md="6"
                >
                  <v-text-field
                    v-model="shipmentLocal.value"
                    v-validate="
                      'required|max_value:800|min_value:0.001'
                    "
                    
                    :error-messages="errs.collect('value')"
                    data-vv-name="value"
                    label="Retail Value"
                    dense
                    outlined hide-details 
                    type="number"
                  />
                </v-col> -->

                <!-- <v-col
                  cols="12"
                  sm="6"
                  md="6"
                >
                  <v-select
                    v-model="shipmentLocal.currency"
                    v-validate="'required'"
                    
                    :error-messages="errs.collect('currency')"
                    data-vv-name="currency"
                    label="Currency"
                    :items="$currencyAsFlatArray()"
                    dense
                    outlined hide-details 
                  />
                </v-col> -->

                <v-col
                  cols="12"
                  sm="12"
                >
                  <v-select
                    v-model="shipmentLocal.postage_type_id"
                    v-validate="''"
                    :error-messages="errs.collect('postage_type')"
                    data-vv-name="postage_type"
                    :items="postageArray"
                    item-text="description"
                    item-value="id"
                    label="Postage Type"
                    dense
                    outlined
                    hide-details
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="6"
                >
                  <v-select
                    v-model="shipmentLocal.signature_confirmation"
                    v-validate="''"
                    :error-messages="errs.collect('signature')"
                    data-vv-name="signature"
                    :items="[
                      { value: 1, text: 'Yes' },
                      { value: 0, text: 'No' }
                    ]"
                    dense
                    outlined
                    hide-details
                    label="Signature"
                  />
                </v-col>

                <v-col
                  v-if="shipmentLocal.needs_postage == false"
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    v-model="shipmentLocal.tracking_code"
                    v-validate="''"
                    outlined
                    hide-details
                    dense
                    :error-messages="errs.collect('tracking_code')"
                    data-vv-name="tracking_code"
                    label="Tracking Number"
                  />
                </v-col>

                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                >
                  <v-select
                    v-model="shipmentLocal.insured"
                    v-validate="''"
                    :error-messages="errs.collect('stallion_protection')"
                    data-vv-name="stallion_protection"
                    :items="[
                      { value: 1, text: 'Yes' },
                      { value: 0, text: 'No' }
                    ]"
                    dense
                    outlined
                    hide-details
                    label="Stallion Protection"
                  />
                </v-col>
                <v-col cols="12">
                  <v-divider />
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12">
                  <span class="font-weight-bold">Rates</span>
                  <v-tooltip top>
                    <template #activator="{ on }">
                      <v-btn
                        :disabled="rateLoading"
                        small
                        icon
                        color="primary"
                        class="float-right"
                        v-on="on"
                        @click="getPostageRates()"
                      >
                        <v-icon :class="rateLoading ? 'mdi-spin' : ''">
                          mdi-refresh
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Click to get rates</span>
                  </v-tooltip>
                </v-col>

                <v-col
                  v-if="rateLoading"
                  cols="12"
                  class="text-center my-3"
                >
                  <span><v-progress-linear
                    height="20"
                    indeterminate
                  >Getting Rates</v-progress-linear></span>
                </v-col>
              </v-row>

              <v-row
                v-if="

                  postageRates.length > 0
                "
                dense
              >
                <v-col cols="12">
                  <rates-list
                    dense
                    show-total
                    :postage-rates="postageRates"
                    :shipment="shipmentLocal"
                    :update-rate-handler="updateShipmentPostage"
                  />
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <!-- <v-expansion-panel :key="1">
            <v-expansion-panel-header disable-icon-rotate expand-icon="mdi-map">
              Package Contents
            </v-expansion-panel-header>
            <v-expansion-panel-content> -->
          <!-- <v-row dense>
                <v-col cols="12">
                  <p>Total Value: {{ getCombinedValue(shipmentLocal?.items) }}</p>
                </v-col>
              </v-row> -->

          <!-- </v-expansion-panel-content>
          </v-expansion-panel> -->

          <v-expansion-panel :key="2">
            <v-expansion-panel-header
              disable-icon-rotate
              expand-icon="mdi-clipboard-text-outline"
            >
              Items
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row dense>
                <v-col cols="12">
                  <p>Total Value: {{ getCombinedValue(shipmentLocal?.items) }}</p>
                </v-col>
              </v-row>
              <v-row
                v-for="(item, index) in shipmentLocal?.items"
                :key="index"
                dense
              >
                <v-col cols="6">
                  <strong>Line Item {{ index + 1 }}</strong>
                </v-col>
                <v-col
                  cols="6"
                  class="text-right"
                >
                  <v-btn
                    color="error"
                    x-small
                    depressed
                    @click="removeItem(index)"
                  >
                    <v-icon small>
                      mdi-minus
                    </v-icon>Remove
                  </v-btn>
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    v-if="shipmentLocal.is_fba"
                    :ref="`asin_${index}`"
                    v-model="item.sku"
                    v-validate="'required'"
                    outlined
                    dense
                    hide-details
                    :error-messages="errs.collect(`asin_${index}`)"
                    label="ASIN"
                    :data-vv-name="`asin_${index}`"
                    required
                  />
                  <v-text-field
                    v-else
                    :ref="`sku_${index}`"
                    v-model="item.sku"
                    v-validate="''"
                    outlined
                    dense
                    hide-details
                    :error-messages="errs.collect(`sku_${index}`)"
                    label="SKU"
                    :data-vv-name="`sku_${index}`"
                    required
                  />
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    v-model="item.description"
                    v-validate="'required'"
                    outlined
                    hide-details
                    dense
                    :error-messages="errs.collect(`description_${index}`)"
                    label="Description"
                    :data-vv-name="`description_${index}`"
                    required
                  />
                </v-col>

                <v-col cols="4">
                  <v-text-field
                    v-model="item.quantity"
                    v-validate="'required|min_value:1|integer'"
                    outlined
                    hide-details
                    dense
                    :error-messages="errs.collect(`quantity_${index}`)"
                    label="Qty"
                    :data-vv-name="`quantity_${index}`"
                    type="number"
                    required
                  />
                </v-col>

                <v-col cols="4">
                  <v-text-field
                    v-model="item.value"
                    v-validate="'required|min_value:0.01'"
                    outlined
                    hide-details
                    dense
                    :error-messages="errs.collect(`value_${index}`)"
                    label="Unit Value"
                    :data-vv-name="`value_${index}`"
                    required
                  />
                </v-col>
                <v-col cols="4">
                  <v-select
                    v-model="item.currency"
                    v-validate="'required'"
                    :error-messages="errs.collect(`currency_${index}`)"
                    :data-vv-name="`currency_${index}`"
                    :items="$currencyAsFlatArray()"
                    dense
                    outlined
                    label="Currency"
                    hide-details
                  />
                </v-col>

                <v-col
                  v-if="showCustomsForm(shipmentLocal.country_code, shipmentLocal.province_code)"
                  cols="12"
                >
                  <v-text-field
                    :ref="`customs_description_${index}`"
                    v-model="item.customs_description"
                    v-validate="''"
                    outlined
                    dense
                    hide-details
                    :error-messages="errs.collect(`customs_description_${index}`)"
                    label="Customs Description"
                    :data-vv-name="`customs_description_${index}`"
                    required
                  />
                </v-col>

                <v-col
                  v-if="showCustomsForm(shipmentLocal.country_code, shipmentLocal.province_code)"
                  cols="6"
                >
                  <v-text-field
                    v-model="item.hs_code"
                    v-validate="''"
                    outlined
                    hide-details
                    dense
                    :error-messages="errs.collect(`hs_code_${index}`)"
                    label="HS Code"
                    :data-vv-name="`hs_code_${index}`"
                    required
                  />
                </v-col>
                <v-col
                  v-if="showCustomsForm(shipmentLocal.country_code, shipmentLocal.province_code)"
                  cols="6"
                >
                  <v-autocomplete
                    v-model="item.country_of_origin"
                    v-validate="''"
                    outlined
                    hide-details
                    dense
                    :error-messages="errs.collect(`country_of_origin_${index}`)"
                    :data-vv-name="`country_of_origin_${index}`"
                    :items="countries"
                    label="Country of Origin"
                    item-text="name"
                    item-value="code"
                  />
                </v-col>
                
                <v-col
                  v-if="showManufacturerInfo(shipmentLocal.country_code)"
                  cols="12"
                >
                  <v-divider class="my-2" />
                  <div class="text-subtitle-2 mb-2">
                    Manufacturer Information
                  </div>
                </v-col>
                
                <v-col
                  v-if="showManufacturerInfo(shipmentLocal.country_code)"
                  cols="12"
                >
                  <v-combobox
                    v-model="item.manufacturer_name"
                    v-validate="''"
                    outlined
                    hide-details
                    dense
                    :error-messages="errs.collect(`manufacturer_name_${index}`)"
                    label="Manufacturer Name"
                    :data-vv-name="`manufacturer_name_${index}`"
                    :items="manufacturerNames"
                    :filter="customFilterManufacturers"
                    clearable
                    @change="handleManufacturerSelection(index)"
                  />
                </v-col>
                
                <v-col
                  v-if="showManufacturerInfo(shipmentLocal.country_code)"
                  cols="12"
                >
                  <v-text-field
                    v-model="item.manufacturer_address1"
                    v-validate="''"
                    outlined
                    hide-details
                    dense
                    :error-messages="errs.collect(`manufacturer_address1_${index}`)"
                    label="Manufacturer Address"
                    :data-vv-name="`manufacturer_address1_${index}`"
                  />
                </v-col>
                
                <v-col
                  v-if="showManufacturerInfo(shipmentLocal.country_code)"
                  cols="6"
                >
                  <v-text-field
                    v-model="item.manufacturer_city"
                    v-validate="''"
                    outlined
                    hide-details
                    dense
                    :error-messages="errs.collect(`manufacturer_city_${index}`)"
                    label="Manufacturer City"
                    :data-vv-name="`manufacturer_city_${index}`"
                  />
                </v-col>
                
                <v-col
                  v-if="showManufacturerInfo(shipmentLocal.country_code)"
                  cols="6"
                >
                  <v-text-field
                    v-model="item.manufacturer_province_code"
                    v-validate="''"
                    outlined
                    hide-details
                    dense
                    :error-messages="errs.collect(`manufacturer_province_code_${index}`)"
                    label="Manufacturer Province/State"
                    :data-vv-name="`manufacturer_province_code_${index}`"
                  />
                </v-col>
                
                <v-col
                  v-if="showManufacturerInfo(shipmentLocal.country_code)"
                  cols="6"
                >
                  <v-text-field
                    v-model="item.manufacturer_postal_code"
                    v-validate="''"
                    outlined
                    hide-details
                    dense
                    :error-messages="errs.collect(`manufacturer_postal_code_${index}`)"
                    label="Manufacturer Postal Code"
                    :data-vv-name="`manufacturer_postal_code_${index}`"
                  />
                </v-col>
                
                <v-col
                  v-if="showManufacturerInfo(shipmentLocal.country_code)"
                  cols="6"
                >
                  <v-autocomplete
                    v-model="item.manufacturer_country_code"
                    v-validate="''"
                    outlined
                    hide-details
                    dense
                    :error-messages="errs.collect(`manufacturer_country_code_${index}`)"
                    :data-vv-name="`manufacturer_country_code_${index}`"
                    :items="countries"
                    label="Manufacturer Country"
                    item-text="name"
                    item-value="code"
                  />
                </v-col>

                <v-col
                  v-if="index != shipmentLocal?.items.length - 1"
                  cols="12"
                >
                  <v-divider />
                </v-col>
              </v-row>

              <v-row>
                <v-col
                  cols="12"
                  class="text-right"
                >
                  <v-btn
                    color="primary"
                    x-small
                    depressed
                    @click="addItem"
                  >
                    <v-icon small>
                      mdi-plus
                    </v-icon>Add Line
                  </v-btn>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <!-- <v-expansion-panel :key="1">
            <v-expansion-panel-header disable-icon-rotate expand-icon="mdi-map">
              Package Contents
            </v-expansion-panel-header>
            <v-expansion-panel-content> -->
          <!-- <v-row dense>
                <v-col cols="12">
                  <p>Total Value: {{ getCombinedValue(shipmentLocal?.items) }}</p>
                </v-col>
              </v-row> -->

          <!-- </v-expansion-panel-content>
          </v-expansion-panel> -->

          <v-expansion-panel :key="3">
            <v-expansion-panel-header
              disable-icon-rotate
              expand-icon="mdi-clipboard-text-outline"
            >
              Order
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row
                dense
                style="margin-top: 10px"
              >
                <v-col
                  cols="12"
                  sm="12"
                >
                  <v-text-field
                    v-model="shipmentLocal.order_id"
                    outlined
                    hide-details
                    dense
                    label="Order ID (Optional)"
                  />
                </v-col>

                <v-col
                  v-if="userStores.user_stores.length > 0"
                  cols="12"
                  sm="12"
                >
                  <v-select
                    v-model="shipmentLocal.user_store_id"
                    :items="userStores.user_stores"
                    label="Store (Optional)"
                    single-line
                    item-text="identifier"
                    item-value="id"
                    outlined
                    hide-details
                    dense
                  />
                </v-col>

                <!-- <v-col
                  cols="12"
                  class="text-right"
                >
                  <v-btn
       
                    color="primary"
                    :loading="updateLoading || purchaseLoading"
                    :disabled="updateLoading || purchaseLoading"
                    small
                    outlined hide-details 
                    @click="save()"
                  >
                    <v-icon
                      small
                      left
                    >
                      mdi-content-save
                    </v-icon>
                    Save
                  </v-btn>
                </v-col> -->
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel :key="4">
            <v-expansion-panel-header
              disable-icon-rotate
              expand-icon="mdi-map"
            >
              Address
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row
                dense
                style="margin-top: 10px"
              >
                <v-col cols="12">
                  <address-verification-details :shipment="shipmentLocal" />
                </v-col>

                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    v-model="shipmentLocal.name"
                    v-validate="'required'"
                    outlined
                    hide-details
                    dense
                    :error-messages="errs.collect('name')"
                    label="Name"
                    data-vv-name="name"
                    required
                    @paste="onPaste"
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    v-model="shipmentLocal.company"
                    outlined
                    hide-details
                    dense
                    :error-messages="errs.collect('company')"
                    label="Company"
                    data-vv-name="company"
                  />
                </v-col>

                <!-- Address1 -->
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    v-model="shipmentLocal.address1"
                    v-validate="'required|max:50'"
                    outlined
                    hide-details
                    dense
                    :error-messages="errs.collect('address1')"
                    label="Address1"
                    data-vv-name="address1"
                  />
                </v-col>

                <!-- Address2 -->
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    v-model="shipmentLocal.address2"
                    v-validate
                    outlined
                    hide-details
                    dense
                    :error-messages="errs.collect('address2')"
                    label="Address2"
                    data-vv-name="address2"
                  />
                </v-col>

                <!-- City -->
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    v-model="shipmentLocal.city"
                    v-validate="'required'"
                    outlined
                    hide-details
                    dense
                    :error-messages="errs.collect('city')"
                    label="City"
                    data-vv-name="city"
                    required
                  />
                </v-col>

                <!-- Country code -->
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-autocomplete
                    v-model="shipmentLocal.country_code"
                    v-validate="'required'"
                    outlined
                    hide-details
                    dense
                    :error-messages="errs.collect('country_code')"
                    data-vv-name="country_code"
                    :items="countries"
                    label="Country"
                    item-text="name"
                    item-value="code"
                    :search-input.sync="searchInput"
                    dusk="sf_country"
                  />
                </v-col>

                <!-- Province/State -->
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-autocomplete
                    v-if="shipmentLocal.country_code == 'CA'"
                    v-model="shipmentLocal.province_code"
                    v-validate="'required|max:2'"
                    outlined
                    hide-details
                    dense
                    :error-messages="errs.collect('province_code')"
                    data-vv-name="province_code"
                    :items="provinces"
                    label="Province"
                    item-text="name"
                    item-value="code"
                    :search-input.sync="searchInput2"
                    dusk="sf_province"
                  />

                  <v-autocomplete
                    v-else-if="shipmentLocal.country_code == 'US'"
                    v-model="shipmentLocal.province_code"
                    v-validate="'required|max:2'"
                    outlined
                    hide-details
                    :error-messages="errs.collect('province_code')"
                    data-vv-name="province_code"
                    :items="states"
                    label="State"
                    item-text="name"
                    item-value="code"
                    :search-input.sync="searchInput2"
                    dense
                    dusk="sf_province"
                  />

                  <v-text-field
                    v-else
                    v-model="shipmentLocal.province_code"
                    outlined
                    hide-details
                    dense
                    dusk="sf_province"
                    label="Province/State"
                  />
                </v-col>

                <!-- Postal Code -->
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    v-if="shipmentLocal.country_code == 'CA'"
                    v-model="shipmentLocal.postal_code"
                    v-mask="'A#A #A#'"
                    v-validate="{
                      required: true,
                      regex: /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/
                    }"
                    outlined
                    hide-details
                    dense
                    validate-on-blur
                    :error-messages="errs.collect('postal_code')"
                    label="Postal Code"
                    data-vv-name="postal_code"
                    dusk="sf_postal_code"
                  />

                  <v-text-field
                    v-else-if="shipmentLocal.country_code == 'US'"
                    v-model="shipmentLocal.postal_code"
                    v-validate="{
                      required: true,
                      regex: /(^\d{5}$)|(^\d{5}-\d{4}$)/
                    }"
                    outlined
                    hide-details
                    dense
                    validate-on-blur
                    :class="{
                      'is-invalid': errs.first('postal_code')
                    }"
                    :error-messages="errs.collect('postal_code')"
                    label="Zip Code"
                    data-vv-name="postal_code"
                  />

                  <v-text-field
                    v-else
                    v-model="shipmentLocal.postal_code"
                    outlined
                    hide-details
                    dense
                    :error-messages="errs.collect('postal_code')"
                    label="Postal Code"
                    data-vv-name="postal_code"
                    dusk="sf_postal_code"
                  />
                </v-col>

                <!-- Phone -->
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    v-if="['CA', 'US'].includes(shipmentLocal.country_code)"
                    v-model="shipmentLocal.phone"
                    v-validate
                    outlined
                    hide-details
                    dense
                    :error-messages="errs.collect('phone')"
                    label="Phone"
                    data-vv-name="phone"
                    required
                  />

                  <v-text-field
                    v-else
                    v-model="shipmentLocal.phone"
                    v-validate
                    outlined
                    hide-details
                    dense
                    :error-messages="errs.collect('phone')"
                    label="Phone"
                    data-vv-name="phone"
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    v-model="shipmentLocal.email"
                    v-validate="'email'"
                    clearable
                    :error-messages="errs.collect('email')"
                    label="Email"
                    data-vv-name="email"
                    outlined
                    hide-details
                    dense
                  />
                </v-col>

                <v-col
                  v-if="!['CA', 'US'].includes(shipmentLocal.country_code)"
                  cols="12"
                >
                  <v-autocomplete
                    v-model="shipmentLocal.tax_id"
                    :error-messages="errs.collect('tax_id')"
                    data-vv-name="tax_id"
                    :items="taxIdentifiers"
                    :item-text="(i) => i.nickname + `(${i.number})`"
                    item-value="id"
                    label="Tax ID"
                    outlined
                    hide-details
                    dense
                  />
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    v-model="shipmentLocal.email"
                    v-validate="'email'"
                    :error-messages="errs.collect('email')"
                    label="Email"
                    data-vv-name="email"
                    outlined
                    hide-details
                    dense
                  />
                </v-col>

                <!-- <v-col
                  cols="12"
                  class="text-right"
                >
                  <v-btn
       
                    color="primary"    
                    :disabled="updateLoading || purchaseLoading"
                    small
                    outlined hide-details 
                    @click="save()"
                  >
                    <v-icon
                      small
                      left
                    >
                      mdi-content-save
                    </v-icon>
                    Save
                  </v-btn>
                </v-col> -->
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>

      <div class="shipment-purchase-btn-container">
        <v-tooltip top>
          <template #activator="{ on }">
            <v-btn
              large
              color="primary"
              :loading="updateLoading || purchaseLoading"
              :disabled="updateLoading || purchaseLoading || showOverlay || shipmentLocal.postage_type_id === null"
              type="submit"
              class="shipment-purchase-btn"
              v-on="on"
              @click="submitForm()"
            >
              <v-icon
                left
                small
              >
                mdi-cube-send
              </v-icon>
              Purchase <span
                v-if="total"
                class="pl-1"
              > ({{ total |currency }})</span>
              <span
                v-else
                class="pl-1"
              > ($0.00)</span>
            </v-btn>
          </template>
          <span>Ctrl + P</span>
        </v-tooltip>
      </div>



      <apply-preset-dialog
        ref="presetDialog"
        v-model="showPresetsDialog"
        dialog-label="Apply Presets"
        :shipments="[shipmentLocal]"
        @initializeHotkeys="initializeHotkeys"
        @update="onApplyPresets"
      />
    </v-row>
  </v-container>
</template>

<script>
import RatesList from '../../rates-list.vue';
import ApplyPresetDialog from '../../../../main/shipments/BulkEdit.vue'
import AutoScaleButton from "../../AutoScaleButton.vue";
import BaseQuickShipForm from '../../quick-ship/BaseQuickShipForm.vue';
import CONSTANTS from '@/constants';

export default {
  components: {
    AutoScaleButton,
    ApplyPresetDialog,
    RatesList
  },
  extends: BaseQuickShipForm,
  props: {
    canEdit: {
      type: Boolean,
      default: false
    },
    showOverlay: {
      type: Boolean,
      default: false
    },
    order: {
      type: Object,
      default: () => ({})
    },
  },

  data() {
    return {
      loading: false,
      selectedPanel: 0, // Shipping
      // activates the required properties in the parent component @BaseQuickShipForm.vue
      showQuickShipForm: this.canEdit,
      debounceTimer: null,
      prevShipmentLocal: {}, // Add this to track changes manually
      total: null,

      predefinedBoxes: [],
      selectedBoxId: 1,
      manufacturers: [],
      unitConversionRates: {
        kg: {
          g: 1000,
          oz: 35.274,
          lbs: 2.20462,
        },
        g: {
          kg: 0.001,
          oz: 0.035274,
          lbs: 0.00220462,
        },
        oz: {
          kg: 0.0283495,
          g: 28.3495,
          lbs: 0.0625,
        },
        lbs: {
          kg: 0.453592,
          g: 453.592,
          oz: 16,
        },
      },
    };
  },
  computed: {
    manufacturerNames() {
      return this.manufacturers.map(m => m.name);
    }
  },
  watch: {
    'shipmentLocal': {
      handler(newVal, oldValue) {
        // ignore if status_id != 2 
        if (this.shipmentLocal.status_id != 3) {
          return;
        }

        const timeout = oldValue ? 500 : 0; // Initial run should be immediate

        const fields = [
          'address1',
          'address2',
          'city',
          'province_code',
          'country_code',
          'postal_code',
          'weight',
          'weight_unit',
          'package_type_id',
          'return_label',
          'signature_confirmation',
          'insured',
        ];

        const dimFields = [
          'length',
          'width',
          'height',
          'size_unit',
        ];

        console.log('shipmentLocal', newVal);
        const changedKeys = Object.keys(newVal).filter(key => newVal[key] !== this.prevShipmentLocal[key]);
        console.log('Changed keys:', changedKeys);

        this.prevShipmentLocal = JSON.parse(JSON.stringify(this.shipmentLocal));

        const changedFields = changedKeys.filter(key => fields.includes(key) || dimFields.includes(key));

        if (changedFields.length === 0) {
          return;
        }

        console.log('Changed fields:', changedFields);

        this.clearPostageRates();

        // Check if the basic required properties are not null
        const basicConditionsMet = this.shipmentLocal.weight && this.shipmentLocal.weight > 0 && this.shipmentLocal.weight_unit && this.shipmentLocal.package_type_id && this.shipmentLocal.country_code && this.shipmentLocal.province_code && this.shipmentLocal.postal_code && this.shipmentLocal.address1 && !this.shipmentLocal.return_label;

        // Additional check for package_type_id 20 for dimensions
        const dimensionsConditionMet = this.shipmentLocal.package_type_id !== 20 || (this.shipmentLocal.length > 0 && this.shipmentLocal.width > 0 && this.shipmentLocal.height > 0);

        console.log('basicConditionsMet', basicConditionsMet);
        console.log('dimensionsConditionMet', dimensionsConditionMet);

        if (basicConditionsMet && dimensionsConditionMet) {
          // Clear the previous timer if it exists
          if (this.debounceTimer) {
            clearTimeout(this.debounceTimer);
          }
          // Set a new timer
          this.debounceTimer = setTimeout(() => {
            console.log('getPostageRates called from watcher');
            this.getPostageRates();
          }, timeout); // Using 1000ms (1 second) as per your comment, adjust as needed
        }
      },
      deep: true, // Watch for nested properties
      immediate: false, // Do not run immediately on component mount
    },
    // 'shipmentLocal.weight_unit'(newUnit, oldUnit) {
    //   if (this.shipmentLocal.weight && oldUnit !== newUnit) {
    //     const conversionRate = this.unitConversionRates[oldUnit]?.[newUnit];
    //     if (conversionRate) {
    //       this.shipmentLocal.weight *= conversionRate;
    //       this.shipmentLocal.weight = parseFloat(this.shipmentLocal.weight.toFixed(2)); // Keep 3 decimal places
    //     }
    //   }
    // },
    'shipmentLocal.length': 'clearApplyBox',
    'shipmentLocal.width': 'clearApplyBox',
    'shipmentLocal.height': 'clearApplyBox',
    'shipmentLocal.size_unit': 'clearApplyBox',
    'shipmentLocal.package_type_id': 'clearApplyBox',
  },
  async mounted() {
    await this.loadPredefinedBoxes();
    await this.loadManufacturers();

    if (this.showQuickShipForm) {
      if (this.isScaleInitialized() && this.$store.state.qz.enabledAutoWeightFetch) {
        await this.$store.dispatch('qz/updateQuickShipStatus', true);
        if (!this.$store.state.qz.currentScaleStream) {
          await this.startScaleStream();
        }
      }

      // parent component BaseQuickShipForm.vue
      this.initializeShipmentLocal();

      if (this.shipmentLocal.items.length === 0) {
        this.addItem()
      }
    }
  },
  methods: {
    showCustomsForm(countryCode, provinceCode) {
      // Show customs form for international shipments (outside Canada and US)
      return countryCode && countryCode !== 'CA' && (provinceCode || countryCode !== 'US');
    },
    
    async loadManufacturers() {
      try {
        const response = await this.$http.get('/manufacturers');
        if (response.data.success) {
          this.manufacturers = response.data.manufacturers;
        }
      } catch (error) {
        console.error('Error loading manufacturers:', error);
      }
    },

    customFilterManufacturers(item, queryText) {
      const name = item.name || '';
      const searchText = queryText.toLowerCase();
      return name.toLowerCase().includes(searchText);
    },
    
    handleManufacturerSelection(index) {
      const manufacturerName = this.shipmentLocal.items[index].manufacturer_name;
      const selectedManufacturer = this.manufacturers.find(m => m.name === manufacturerName);
      
      if (selectedManufacturer) {
        // Populate all manufacturer fields
        this.shipmentLocal.items[index].manufacturer_address1 = selectedManufacturer.address1;
        this.shipmentLocal.items[index].manufacturer_city = selectedManufacturer.city;
        this.shipmentLocal.items[index].manufacturer_province_code = selectedManufacturer.province_code;
        this.shipmentLocal.items[index].manufacturer_postal_code = selectedManufacturer.postal_code;
        this.shipmentLocal.items[index].manufacturer_country_code = selectedManufacturer.country_code;
      }
    },
    onPaste(event) {
      const clipboardData = event.clipboardData || window.clipboardData;
      const pastedData = clipboardData.getData('Text').trim();

      // Example: Make a request to a parsing endpoint or use a library
      
      this.$http
        .post('/shipments/address-parse', { address: pastedData })
        .then(({ data }) => {
          const {
            name,
            company,
            address1,
            address2,
            city,
            province_code,
            postal_code,
            country_code,
          } = data;

          this.shipmentLocal.name = name || '';
          this.shipmentLocal.company = company || '';
          this.shipmentLocal.address1 = address1 || '';
          this.shipmentLocal.address2 = address2 || '';
          this.shipmentLocal.city = city || '';
          this.shipmentLocal.province_code = province_code || '';
          this.shipmentLocal.postal_code = postal_code || '';
          this.shipmentLocal.country_code = country_code || '';
        })
        .catch((error) => {
          console.error('Address parsing failed', error);
          this.$notify({
            group: 'main',
            title: 'Error',
            text: 'Failed to parse address. Please try again.',
            type: 'error',
          });
        });
    },
    async loadPredefinedBoxes() {
      try {
        const response = await this.$http.get('/boxes'); // Adjust if needed to match API
        let boxes = response.data.map(box => ({
          ...box,
          name: `${box.length}x${box.width}x${box.height} ${box.size_unit} - ${box.name}`
        }));

        this.predefinedBoxes = boxes;
      } catch (error) {
        console.error('Error loading predefined boxes:', error);
      }
    },
    applyBox() {
      const selectedBox = this.predefinedBoxes.find(box => box.id === this.selectedBoxId);
      if (selectedBox) {
        this.shipmentLocal.length = selectedBox.length || 0;
        this.shipmentLocal.width = selectedBox.width || 0;
        this.shipmentLocal.height = selectedBox.height || 0;
        this.shipmentLocal.size_unit = selectedBox.size_unit || 'in';
        this.shipmentLocal.package_type_id = selectedBox.package_type_id || 20;
      }
      
    },
    clearApplyBox() {
      // clear if any of the dimension fields are changed from the predefined box
      const selectedBox = this.predefinedBoxes.find(box => box.id === this.selectedBoxId);

      if(selectedBox) {
        if(this.shipmentLocal.length !== selectedBox.length || this.shipmentLocal.width !== selectedBox.width || this.shipmentLocal.height !== selectedBox.height || this.shipmentLocal.size_unit !== selectedBox.size_unit || this.shipmentLocal.package_type_id !== selectedBox.package_type_id) {
          this.selectedBoxId = null;
        }
      }
    },
    changeWeightUnit() {
      const weightUnits = ['kg', 'lbs', 'g', 'oz']; // Available weight units
      const currentIndex = weightUnits.indexOf(this.shipmentLocal.weight_unit);
      const newIndex = (currentIndex + 1) % weightUnits.length; // Cycle to the next unit

      this.shipmentLocal.weight_unit = weightUnits[newIndex];
    },
    getAppendIcon() {
      switch (this.shipmentLocal.weight_unit) {
        case 'kg':
          return 'mdi-weight-kilogram';
        case 'lbs':
          return 'mdi-weight-pound';
        case 'g':
          return 'mdi-weight-gram';
        case 'oz':
          return 'mdi-alpha-o-circle';
        default:
          return 'mdi-circle-off-outline';
      }
    },

    removeItem(index) {
      this.shipmentLocal.items.splice(index, 1);
    },
    addItem() {
      this.shipmentLocal.items.push({ ...CONSTANTS.SHIPMENT_ITEM });
    },
    updateShipmentPostage(rate) {
      this.shipmentLocal.postage_type_id = rate?.postage_type_id || null;
      this.shipmentLocal.rate_id = rate?.id || null
      this.total = rate?.total || null;
    },

    undoSplit() {
      if (confirm('Are you sure you want to undo the split?')) {

        const order_hash = this.shipmentLocal.original_order_hash;
        this.$http
          .post('/orders/undo-split', {
            order_hash
          })
          .then(() => {
            this.$emit('update');
            this.successMessage('Split undone successfully');
          })
          .catch(error => {
            if (error.response) {
              this.errorMessage(error.response.data.message);
            } else {
              this.errorMessage('Could not undo split');
            }
          });
      }
    },

    unmergeOrders() {
      if (confirm('Are you sure you want to unmerge this order?')) {
        this.$http
          .post('/orders/unmerge', {
            orderID: this.order.id
          })
          .then(() => {
            this.$emit('update');
            this.successMessage('Order unmerged successfully');
          })
          .catch(error => {
            if (error.response) {
              this.errorMessage(error.response.data.message);
            } else {
              this.errorMessage('Could not unmerge order');
            }
          });
      }
    },
  }
};
</script>
<style>
.edit-order-expansion-panel .v-expansion-panel-content__wrap {
  padding: 0;
  padding-top: 10px;
  padding-right: 0px;
  padding-bottom: 20px;
  padding-left: 0px;
  flex: 1 1 auto;
  max-width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  margin-bottom: 10px;
}

.edit-order-expansion-panel .v-expansion-panel--active>.v-expansion-panel-header {
  min-height: 40px !important;
  max-height: 40px !important;
  /* color: #f9ac40; */
  border-radius: 5px !important;
  margin-top: 0px !important;
  border: 1px solid rgba(0, 0, 0, 0.12);
}

.edit-order-expansion-panel .v-expansion-panel-header {
  min-height: 40px !important;
  max-height: 40px !important;
}

.v-application--is-ltr .v-text-field .v-input__append-inner {
  /* display: none */
}

.shipment-purchase-btn {

  width: 100%;
  /* margin-left: -10px !important; */


  /* height: 54px !important; */

  /* box shadow */
  /* box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.2) !important; */
}

.shipment-purchase-btn.theme--light.v-btn.v-btn--disabled.v-btn--has-bg {
  background-color: #e0e0e0 !important;
}

.shipment-purchase-btn-container {
  padding: 8px 5px 8px 2px;
  position: fixed;
  bottom: 0;
  display: flex;
  justify-content: flex-start;
  width: 95%;
  background-color: white !important;
  z-index: 1;
  text-align: center;
  border-top: 1px solid #e0e0e0;
}
.shipment-details .v-input__append-inner{
  display: none !important;
}
</style>