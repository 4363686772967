<template>
  <div v-if="rateDetails">
    <div>
      <h4>Rate Breakdown</h4>
      <!-- <div class="rate-title">Weight</div> -->

      <div class="rate-block">
        <div class="d-flex flex-row justify-space-between">
          <div class="rate-title">
            Postage Cost
          </div>
          <div>
            {{ rateDetails.postage_cost | currency }}
          </div>
        </div>

        <div
          v-if="rateDetails.base_currency != 'CAD'"
          class="text-muted"
        >
          {{ rateDetails.base_postage_cost | currency
          }}{{ rateDetails.base_currency }} x
          {{ rateDetails.exchange_rate | ratio }}%
        </div>
      </div>

      <div
        v-if="rateDetails.conversion_fee > 0"
        class="rate-block"
      >
        <div class="d-flex flex-row justify-space-between">
          <div class="rate-title">
            Conversion Fee
          </div>
          <div>
            {{ rateDetails.conversion_fee | currency }}
          </div>
        </div>
        <div class="text-muted">
          {{ rateDetails.base_postage_cost | currency
          }}{{ rateDetails.base_currency }} x
          {{ rateDetails.conversion_fee_rate | ratio }}%
        </div>
      </div>

      <div
        v-if="rateDetails.conversion_margin > 0"
        class="rate-block"
      >
        <div class="d-flex flex-row justify-space-between">
          <div class="rate-title">
            Conversion Margin
          </div>
          <div>
            {{ rateDetails.conversion_margin | currency }}
          </div>
        </div>
        <div class="text-muted">
          {{ rateDetails.base_postage_cost | currency
          }}{{ rateDetails.base_currency }} x
          {{ rateDetails.conversion_margin_rate | ratio }}%
        </div>
      </div>

      <div
        v-if="!rateDetails.margin_visible"
        class="rate-block"
      >
        <div class="d-flex flex-row justify-space-between">
          <div class="rate-title">
            Margin<br>
          </div>
          <div>
            {{ rateDetails.margin | currency }}
          </div>
        </div>
        <div class="text-muted">
          {{ rateDetails.tier }}<br>
          {{ rateDetails.original_margin | currency }} - {{ rateDetails.original_margin -rateDetails.margin | currency }}
        </div>
      </div>

      <div class="rate-block">
        <div class="d-flex flex-row justify-space-between">
          <div class="rate-title">
            Base Rate
          </div>
          <div>
            {{ rateDetails.base_rate | currency }}
          </div>
        </div>

        <div class="text-muted">
          {{ rateDetails.postage_cost | currency }}
          <span
            v-if="rateDetails.conversion_fee > 0"
          >+ {{ rateDetails.conversion_fee | currency }}</span>
          <span
            v-if="rateDetails.conversion_margin > 0"
          >+ {{ rateDetails.conversion_margin | currency }}</span>
          <span
            v-if="!rateDetails.margin_visible"
          >+ {{ rateDetails.margin | currency }}</span>
        </div>
      </div>

      <div
        v-if="rateDetails.add_ons.length > 0"
        class="rate-block"
      >
        <div class="d-flex flex-row justify-space-between">
          <div class="rate-title">
            Add Ons
          </div>
          <div>
            {{ addon_total | currency }}
          </div>
        </div>

        <div
          v-for="addon in rateDetails.add_ons"
          :key="addon.type"
        >
          <div class="text-muted">
            {{ addon.type | uppercase }}: {{ addon.cost | currency }}
          </div>
        </div>
      </div>

      <div class="rate-block">
        <div class="d-flex flex-row justify-space-between">
          <div class="rate-title">
            Customer Rate
          </div>
          <div>
            {{ rateDetails.rate | currency }}
          </div>
        </div>

        <div class="text-muted">
          {{ rateDetails.rate | currency }}
        </div>
      </div>

      <div
        v-if="rateDetails.margin_visible"
        class="rate-block"
      >
        <div class="d-flex flex-row justify-space-between">
          <div class="rate-title">
            Handling Fee
          </div>
          <div>
            {{ rateDetails.margin | currency }}
          </div>
        </div>
      </div>

      <div
        v-if="rateDetails.tax > 0"
        class="rate-block"
      >
        <div class="d-flex flex-row justify-space-between">
          <div class="rate-title">
            Taxes
          </div>
          <div>
            {{ rateDetails.tax | currency }}
          </div>
        </div>

        <div v-if="rateDetails.gst > 0">
          <div class="text-muted">
            GST: {{ rateDetails.gst | currency }} =
            {{ rateDetails.rate | currency }} x
            {{ rateDetails.gst_rate | ratio }}%
          </div>
        </div>

        <div v-if="rateDetails.pst > 0">
          <div class="text-muted">
            PST: {{ rateDetails.pst | currency }} =
            {{ rateDetails.rate | currency }} x
            {{ rateDetails.pst_rate | ratio }}%
          </div>
        </div>

        <div v-if="rateDetails.hst > 0">
          <div class="text-muted">
            HST: {{ rateDetails.hst | currency }} =
            {{ rateDetails.rate | currency }} x
            {{ rateDetails.hst_rate | ratio }}%
          </div>
        </div>
      </div>

      <div v-if="rateDetails.duty > 0">
        <div class="rate-block">
          <div class="d-flex flex-row justify-space-between">
            <div class="rate-title">
              Duty Fee
            </div>
            <div>
              {{ rateDetails.duty | currency }}
            </div>
          </div>

          <div class="text-muted">
            {{ rateDetails.duty | currency }}
          </div>
        </div>
      </div>

      <div v-if="rateDetails.duty_tax > 0">
        <div class="rate-block">
          <div class="d-flex flex-row justify-space-between">
            <div class="rate-title">
              Duty & Tax
            </div>
            <div>
              {{ (rateDetails.duty_tax + rateDetails.duty) | currency }}
            </div>
          </div>

          <div class="text-muted">
            {{ rateDetails.duty | currency }} + {{ rateDetails.duty_tax | currency }}
          </div>
        </div>
      </div>

      <div class="rate-block">
        <div class="d-flex flex-row justify-space-between">
          <div class="rate-title">
            Total
          </div>
          <div>
            {{ rateDetails.total | currency }}
          </div>
        </div>
        <div class="text-muted">
          {{ rateDetails.rate | currency }}
          <span
            v-if="rateDetails.margin_visible"
          >+ {{ rateDetails.margin | currency }}</span>

          <span
            v-if="rateDetails.tax > 0"
          >+ {{ rateDetails.tax | currency }}</span>

          <span
            v-if="rateDetails.duty_tax > 0"
          >+ {{ rateDetails.duty_tax | currency }}</span>
        </div>
      </div>
      <v-divider class="details-divider" />
    </div>
    <div
      v-if="rateDetails.meta"
      class="rate-block"
    >
      <h4>Rate Metadata</h4>
      <div class="rate-block">
        <pre>{{ rateDetails.meta || json_pretty }}</pre>
      </div>
      <v-divider class="details-divider" />
    </div>

    <!-- <div
      class="d-flex flex-row justify-space-between"
      v-for="(field, index) in rateDetails"
      :key="index"
    >
      <div>{{ index }} {{ field }}</div>
    </div> -->
  </div>
</template>

<script>
export default {
  props: {
    rateDetails: {
      type: Object,
      default: null
    }
  },
  computed: {
    addon_total: function () {
      var count = 0;

      if (this.rateDetails && this.rateDetails.add_ons.length > 0) {
        this.rateDetails.add_ons.forEach(function (addon) {
          count += addon.cost;
        });
      }

      return count;
    }
  }
};
</script>

<style scoped>
.rate-title {
  color: rgb(78, 78, 78);
  font-size: 12px;
  font-weight: 800;
  text-transform: uppercase;
  letter-spacing: 0.8px;
}
.rate-block {
  margin-top: 10px;
}
</style>
