<template>
  <FullPageLayout title="Domestic Scan">
    <template #default>
      <v-flex xs12>
        <v-form>
          <v-container>
            <v-row
              class="pb-2"
              style="justify-content: space-between;"
            >
              <v-text-field
                v-model="barcode"
                label="Manual Entry"
                class="mr-2"
                outlined
                clearable
                dense
                hide-details
                :disabled="loading || print_loading"
                @keydown.enter.prevent="manualScan"
              />

              <v-btn
                color="primary"
                :loading="loading || print_loading"
                @click="manualScan"
              >
                Submit
              </v-btn>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                md="4"
              >
                <v-checkbox
                  v-model="forceCreate"
                  label="Force create return"
                  hint="Will create return even if shipment not found"
                  persistent-hint
                  dense
                />
              </v-col>
              <v-col
                v-if="forceCreate"
                cols="12"
                md="8"
              >
                <UserSearch
                  v-model="selectedUserId"
                  label="Select User"
                  :disabled="loading || print_loading"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-flex>
      <v-flex
        id="scan-page"
        xs12
      >
        <v-flex
          v-for="(item, index) in errors"
          :key="index"
          xs12
        >
          <v-alert
            class="pa-2"
            :value="true"
            dismissible
            type="error"
            transition="fade-transition"
          >
            {{ item }}
          </v-alert>
        </v-flex>
        <v-flex
          v-for="(item, index) in warnings"
          :key="index"
          xs12
        >
          <v-alert
            class="pa-2"
            :value="true"
            dismissible
            type="warning"
            transition="fade-transition"
          >
            {{ item }}
          </v-alert>
        </v-flex>

     
        <v-data-table
          class="table-striped"
          :headers="headers"
          :items="shipments"
          :loading="loading"
          no-data-text="Awaiting scanned items"
          :page.sync="pagination.page"
          :items-per-page.sync="pagination.itemsPerPage"
          :sort-by.sync="pagination.sortBy"
          :sort-desc.sync="pagination.descending"
        >
          <template #item.current_location="{ item }">
            <span v-if="item.current_location_id == 1">Markham</span>
            <span v-else-if="item.current_location_id == 2">Mississauga</span>
          </template>

          <template #item.destination_location="{ item }">
            <span v-if="item.destination_location_id == 1">Markham</span>
            <span
              v-else-if="item.destination_location_id == 2"
            >Mississauga</span>
          </template>

          <template #item.created_at="{ item }">
            {{ item.created_at | date_pretty }}
            <br>
            ({{ item.created_at | datetime_from }})
          </template>
          <!-- <template v-slot:item.tracking="{ item }">
              <div v-if="item.tracking_events.length > 0">{{ item.tracking_events[0].status }}</div>
            </template>-->
        </v-data-table>
      </v-flex>
    </template>
  </FullPageLayout>
</template>

<script>
import UserSearch from '../../../components/admin/users/user-search.vue';

export default {
  components: {
    UserSearch
  },
  data() {
    return {
      shipments: [],
      warnings: [],
      activeBtn: 1,
      showNav: true,
      barcodes: [],
      box: 0,
      barcode: null,
      selected: [],
      forceCreate: false,
      selectedUserId: null,
      headers: [
        {
          text: 'Barcode',
          sortable: false,
          value: 'barcode'
        },
        {
          text: 'User ID',
          sortable: false,
          value: 'user_id'
        },
        {
          text: 'Business',
          sortable: false,
          value: 'user.business_name'
        },

        {
          text: 'Current Location',
          sortable: false,
          value: 'current_location.location'
        },

        {
          text: 'Customer Branch',
          sortable: false,
          value: 'destination_location.location'
        },

        {
          text: 'Created At',
          sortable: false,
          value: 'created_at'
        }
      ],
      pagination: {
        page: 1,
        itemsPerPage: 200,
        sortBy: null,
        descending: false
      },
      loading: false,
      print_loading: false,
      errors: []
    };
  },
  computed: {},
  created() {
    document.activeElement.blur();
    // Add barcode scan listener and pass the callback function
    this.$barcodeScanner.init(this.onBarcodeScanned);
  },
  destroyed() {
    // Remove listener when component is destroyed
    this.$barcodeScanner.destroy();
  },
  mounted() {
    console.log('scan-page');
  },
  methods: {
    manualScan() {
      this.onBarcodeScanned(this.barcode);

      this.barcode = null;
    },
    // Create callback function to receive barcode when the scanner is already done
    onBarcodeScanned(barcode) {
      console.log(barcode);
      if (!barcode) {
        return
      }

      this.$notify({
        group: 'main',
        title: 'Item Scanned',
        text: barcode,
        duration: 3000
      });

      this.loading = true
      this.scan(barcode).then(res => {
        // console.log(res);
        this.printReturnLabel(res.shipment.id)
        this.shipments.push(res.shipment);
      })
      .finally(() => {
        this.loading = false;
      });

      // do something...
    },
    // Reset to the last barcode before hitting enter (whatever anything in the input box)
    resetBarcode() {
      this.$barcodeScanner.getPreviousCode();
      // do something...
    },
    scan(barcode) {
      return new Promise((resolve, reject) => {
        const payload = { 
          code: barcode,
          force_create: this.forceCreate,
          user_id: this.forceCreate ? this.selectedUserId : null
        };

        this.$http
          .post('/admin/scan/returns/domestic', payload)
          .then(response => response.data)
          .then(response => {
            if (response.success == true) {
              this.playSuccessSound();
              resolve({
                shipment: response.shipment
              });
            } else {
              this.playErrorSound();
              this.errors.push(response.error);
              reject({});
            }
          })
          .catch(err => {
            this.playErrorSound();
            console.log(err.response);
            this.errors.push('Server error');
            reject({});
          });
      });
    },
    printReturnLabel(id) {
      this.print_loading = true;
      this.$http
        .get(`/admin/return-labels/getlabel/${id}`)
        .then((response => {
          this.printUrl(response.data);
        }))
        .finally(() => {
          this.print_loading = false;
        });
    }
  }
};
</script>
