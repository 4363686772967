<template>
  <SingleCardLayout>
    <v-layout wrap>
      <v-form
        v-if="!sent"
        @submit.prevent="sendLink()"
      >
        <v-container>
          <v-row>
            <v-col cols="12">
              <h2 class="box-title m-b-20">
                Password Reset
              </h2>
              <v-alert
                v-if="isBreachRedirect"
                type="warning"
                icon="mdi-shield-alert"
                prominent
                dense
                outlined
                class="mb-4"
              >
                {{ breachMessage }}
              </v-alert>
              <p>
                Enter your username and we'll send you a code to reset
                your password.
              </p>
            </v-col>

            <v-col cols="12">
              <v-alert
                dark
                color="error"
                :value="error != ''"
                icon="warning"
              >
                {{ error }}
              </v-alert>
            </v-col>

            <v-col cols="12">
              <v-text-field
                v-model="username"
                v-validate="'required'"
                autofocus
                label="Username/Email"
                data-vv-name="username"
                :error-messages="errs.collect('username')"
                type="username"
                name="username"
              />
            </v-col>

            <v-col cols="12">
              <v-layout
                align-center
                class="mt-4 mb-4"
              >
                <v-flex
                  xs12
                  text-center
                >
                  <v-btn
                    color="primary"
                    :loading="loading"
                    type="submit"
                    :disabled="loading"
                    name="forgot-send"
                  >
                    <v-icon
                      small
                      dark
                    >
                      mdi-send
                    </v-icon>Send
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
      <v-flex
        v-else
        xs12
        class="pt-5"
      >
        <v-alert
          type="success"
          class="mt-5"
          outlined
        >
          A reset token has been sent to your email address. Please check your inbox and follow the instructions to reset your password.
        </v-alert>
      </v-flex>
      <v-col cols="12">
        <v-layout
          align-center
          class="mt-4 mb-4"
        >
          <v-flex
            xs12
            text-center
          >
            <router-link
              to="/login"
              aria-expanded="false"
            >
              Return to Login
            </router-link>
          </v-flex>
        </v-layout>
      </v-col>
    </v-layout>
  </SingleCardLayout>
</template>

<script>
import Constants from '@/constants';

export default {
  metaInfo: {
    title: 'Forgot Password - Stallion Express'
  },
  data() {
    return {
      errors: [],
      username: '',
      error: '',
      loading: false,
      sent: false,
      isBreachRedirect: false,
      breachMessage: Constants.PASSWORD_SECURITY.PASSWORD_BREACH_MESSAGE
    };
  },
  mounted() {
    this.sent = false;
    
    // Check if username was passed in the URL (redirected from login page)
    if (this.$route.query.username) {
      this.username = this.$route.query.username;
      this.isBreachRedirect = true;
    }
  },
  methods: {
    sendLink() {
      this.loading = true;
      this.$validator.validate().then((result) => {
        if (result) {
          this.getCsrf().then(() => {
            this.$http
              .post('/auth/password-reset', {
                username: this.username
              })
              .then((res) => {
                console.log(res);
                if (res.data.valid) {
                  this.sent = true;
                } else {
                  swal('Error!', 'Unknown error occurred');
                }
              })
              .then(() => {
                this.loading = false;
              });
          });
        } else {
          this.loading = false;
        }
      });
    }
  }
};
</script>
