<template>
  <StallionDialog
    v-model="dialog"
    persistent
    :width="800"
    :title="'Process Container'"
  >
    <template #content>
      <v-form @submit.prevent>
        <v-container>
          <v-row dense>
            <v-col
              cols="6"
              md="4"
            >
              <h4>
                Container
              </h4>
              <p>{{ container.name }} {{ container.type }}<br> {{ container.day_code }}</p>
            </v-col>
            <v-col
              cols="6"
              md="4"
            >
              <h4>
                Total Shipments
                <v-icon
                  small
                  color="grey lighten-1"
                  @click="getContainerShipments"
                >
                  info
                </v-icon>
              </h4>
              <p>{{ container.shipments_count || 0 }}</p>


              <v-dialog
                v-model="shipmentsDialog"
                fullscreen
                hide-overlay
                transition="dialog-bottom-transition"
              >
                <v-card>
                  <v-toolbar dark>
                    <v-btn
                      icon
                      @click="shipmentsDialog = false"
                    >
                      <v-icon>close</v-icon>
                    </v-btn>
                    <v-toolbar-title>Shipments</v-toolbar-title>
                    <v-spacer />
                    <v-toolbar-items>
                      <v-btn
                        dark
                        text
                        @click="exportShipments"
                      >
                        Export
                      </v-btn>
                    </v-toolbar-items>
                  </v-toolbar>
                  <v-data-table
                    :items="shipments"
                    :headers="headers"
                    :footer-props="{
                      'items-per-page-options': [50, 100, 200, 500]
                    }"
                  >
                    <template #item.created_at="{ item }">
                      {{ item.created_at | date_pretty }}
                      <br>
                      ({{ item.created_at | datetime_from }})
                    </template>

                    <template #item.status_id="{ item }">
                      <div
                        v-html="$options.filters.status(item.status_id)"
                      />
                    </template>

                    <template #item.postage_rate="{ item }">
                      {{ item.postage_rate | currency }}
                    </template>

                    <template #item.postage_type_id="{item }">
                      <div v-html="$options.filters.postage(item)" />
                    </template>
                  </v-data-table>
                </v-card>
              </v-dialog>
            </v-col>

            <v-col
              cols="6"
              md="4"
            >
              <h4>Parent Container</h4>
              <p v-if="container.parent_container">
                {{ container.parent_container.name }} {{ container.parent_container.type }} <br> {{
                  container.parent_container.day_code }}
              </p>
              <p v-else>
                None
              </p>
            </v-col>
            <v-col md="12">
              <h4>
                Child Containers <v-icon
                  small
                  color="grey lighten-1"
                  @click="childContainerDialog = true"
                >
                  info
                </v-icon>
              </h4>
              <div>{{ container?.child_containers?.length || 0 }}</div>

              <v-dialog
                v-model="childContainerDialog"
                fullscreen
                hide-overlay
                transition="dialog-bottom-transition"
              >
                <v-card>
                  <v-toolbar dark>
                    <v-btn
                      icon
                      @click="childContainerDialog = false"
                    >
                      <v-icon>close</v-icon>
                    </v-btn>
                    <v-toolbar-title>Child Containers</v-toolbar-title>
                  </v-toolbar>

                  <v-container>
                    <v-row>
                      <v-col
                        v-for="child_container in container?.child_containers"
                        :key="child_container.id"
                        cols="4"
                      >
                        <v-card
                          class="pa-3"
                          outlined
                        >
                          {{ child_container.name }} {{ child_container.type }} <br> {{ child_container.day_code }}
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card>
              </v-dialog>
            </v-col>
          </v-row>
        </v-container>
        <v-divider v-if="transferContainer" />
        <v-col md="12">
          <div v-if="transferContainer">
            <h4>Transfer to:</h4>
            <p>
              {{ transferContainer.name }} {{ transferContainer.type }} <br>
              {{ transferContainer.day_code }}
            </p>
          </div>
        </v-col>
        <!-- <v-text-field
          v-if="container.output != null && container.status == 'Open'"
          v-model="barcode"
          
          v-container
          outlined
         
          dense
          label="Barcode"
        />

        <v-btn
          v-if="container.output != null && container.status == 'Open'"
          color="primary"
          :loading="submitting"
          @click="onBarcodeScanned(barcode)"
        >
          Scan
        </v-btn> -->
      </v-form>
    </template>

    <template #actions>
      <v-btn
        v-if="container.output != null && container.status == 'Open' && container.type != 'Trailer' && can('containers.admin')"
        color="error"
        :loading="submitting"
        @click="completeContainer()"
      >
        Complete
      </v-btn>
      <v-spacer />
      <v-btn
        v-if="container.output != null && container.status == 'Locked'"
        color="primary"
        :loading="submitting"
        @click="unlockContainer()"
      >
        Unlock
      </v-btn>

      <v-btn
        v-if="container.parent_id != null && container.type != 'Trailer'"
        color="primary"
        :loading="submitting"
        @click="remove()"
      >
        Remove
      </v-btn>

      <v-btn
        v-else-if="container.output != null && container.status == 'Open' && container.type != 'Trailer'"
        color="primary"
        :loading="submitting"
        :disabled="!transferContainer"
        @click="transfer()"
      >
        Transfer
      </v-btn>

      <v-btn
        v-if="container.output != null && container.status == 'Open' && container.type == 'Trailer'"
        color="primary"
        :loading="submitting"
        @click="closeContainer()"
      >
        Close
      </v-btn>
    </template>
  </StallionDialog>
</template>

<script>
export default {
  data() {
    return {
      container: this.getDefaultContainer(),
      edit: false,
      dialog: false,
      submitting: false,
      shipments: [],
      barcode: null,
      transferContainer: null,
      shipmentsDialog: false,
      childContainerDialog: false,
      headers: [
      {
          text: 'Ship Code',
          align: 'left',

          value: 'ship_code'
        },
        {
          text: 'Tracking',
          align: 'left',

          value: 'tracking_code'
        },
        {
          text: 'Status',
          align: 'left',

          value: 'status_id'
        },
        {
          text: 'Postage',
          align: 'left',

          value: 'postage_type_id'
        },
        {
          text: 'Country',
          align: 'left',

          value: 'country_code'
        },
        {
          text: 'Created At',
          align: 'left',

          value: 'created_at'
        },


      ],
    };

  },
  computed: {
  },
  created() {
    document.activeElement.blur();
    // Add barcode scan listener and pass the callback function
    this.$barcodeScanner.init(this.onBarcodeScanned);
    console.log('this.$barcodeScanner init');
  },
  destroyed() {
    // Remove listener when component is destroyed
    this.$barcodeScanner.destroy();
    console.log('this.$barcodeScanner destroy');
  },
  methods: {
    async onBarcodeScanned(barcode) {
      if (!this.dialog) {
        const container = await this.getContainer(barcode);
        this.startEdit(container);
      }
      else {
        if (this.container.status == 'Locked') {
          this.errorMessage('Current container is locked!');
          return;
        }

        const container = await this.getContainer(barcode);

        if (container.id == this.container.id) {
          this.errorMessage('Scanned container is the same as current container!');
          return;
        }

        if (this.container.type === container.type ||
          (this.container.type == 'Gaylord' && container.type == 'Bag') ||
          this.container.type == 'Trailer') {
          this.errorMessage(`Cannot transfer shipments from ${this.container.type} to ${container.type}!`);
          return;
        }

        if (this.container.carrier != null && container.carrier != null && this.container.carrier != container.carrier) {
          this.errorMessage(`Cannot transfer shipments from ${this.container.carrier} to ${container.carrier}!`);
          return;
        }

        if (this.container.type == 'Box' && container.type == 'Container') {
          this.errorMessage('Cannot transfer shipments from box to container!');
          return;
        }

        if (container.status == 'Open') {
          this.transferContainer = container;
        }
        else {
          this.errorMessage('Scanned container is not open!');
          this.transferContainer = null;
        }

        this.barcode = barcode;
      }
    },
    exportShipments() {
      let exportBy = this.sortType;
      if (this.usps_postage_types.includes(this.sortType)) {
        exportBy = 'usps';
      }
      this.$http
        .post(`/admin/export/${exportBy}`, { user: this.selected.user })
        .then((response) => {
          this.downloadFileUrl(response.data, `${this.selected.user.business_name || this.selected.user.name
            } ${this.sortType}.csv`, 'text/csv');
        });
    },
    getContainerShipments() {
      return new Promise((resolve, reject) => {
        this.$http
          .get(`/admin/containers/${this.container.id}/shipments`)
          .then((response) => {
            this.shipments = response.data;
            this.shipmentsDialog = true
            resolve(this.shipments); // Resolve the promise with the shipments data
          })
          .catch((error) => {
            this.onError(error);
            reject(error); // Reject the promise with the error
          })
          .finally(() => {
            this.submitting = false;
          });
      });
    },
    scan() {
      this.transferContainer = this.getTransferContainer();
    },

    getDefaultContainer() {
      return {
        name: '',
        type: '',
        output: ''
      };
    },
    initDialog() {
      this.dialog = true;
      this.submitting = false;
      this.shipments = [];
      this.barcode = null;
      this.transferContainer = null;
      this.$validator.reset();
    },
    closeDialog() {
      this.dialog = false;
      this.$emit('update-boxes');
      this.$store.dispatch('boxes/getContaineres');
    },
    startNew() {
      this.initDialog();
      this.container = { ...this.getDefaultContainer() };
      this.edit = false;
    },
    async startEdit(container) {
      this.initDialog();
      this.container = { ...container };
      this.edit = true;
      // this.shipments = await this.getContainerShipments();
    },
    async validateContainer() {
      return this.$validator.validate().then((result) => {
        return result ? true : false;
      });
    },
    async saveContainer() {
      if (!(await this.validateContainer())) return;
      this.submitting = true;
      this.$http
        .post(`/admin/containers`, this.container)
        .then((data) => {
          this.printUrl(data.data.url, true);
          this.closeDialog();
        })
        .catch((err) => {
          this.onError(err);
        })
        .finally(() => {
          this.submitting = false;
        });
    },
    async closeContainer(){
      const isConfirm = await swal({
        title: 'Close Container',
        text: `Close ${this.container.name} ${this.container.type} ${this.container.day_code}`,
        icon: 'warning',
        buttons: ['Cancel', 'Confirm']
      });

      if (isConfirm) {

        this.submitting = true;
        this.$http
          .post(`/admin/containers/${this.container.id}/close`, this.container)
          .then(() => {
            this.closeDialog();
            this.$notify({
              group: 'main',
              title: 'Container Closed',
              text: `Container successfully closed!`,
              duration: 4000
            });
            this.$emit('update-containers');
          })
          .catch((err) => {
            this.onError(err);
          })
          .finally(() => {
            this.submitting = false;
          });
      }

    },
    async unlockContainer() {
      const isConfirm = await swal({
        title: 'Remove from Sorter',
        text: `Removing ${this.container.name} ${this.container.type} from output ${this.container.output} will add ${this.shipments.length} sorted shipments from output ${this.container.output} to this container.`,
        icon: 'warning',
        buttons: ['Cancel', 'Confirm']
      });

      if (isConfirm) {

        this.submitting = true;
        this.$http
          .post(`/admin/containers/${this.container.id}/unlock`, this.container)
          .then(() => {
            // if (this.container.type != 'Bag') {
              this.closeDialog();
            // }
            // else {
            //   this.container.status = 'Open';
            // }

            this.$notify({
              group: 'main',
              title: 'Container Closed',
              text: `Container successfully closed!`,
              duration: 4000
            });
            this.$emit('update-containers');
          })
          .catch((err) => {
            this.onError(err);
          })
          .finally(() => {
            this.submitting = false;
          });
      }
    },
    getContainer(barcode) {
      return new Promise((resolve, reject) => {
        this.$http
          .get(`/admin/containers/transfer/${barcode}`)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            this.onError(error);
            reject(error);
          })
          .finally(() => {
            this.submitting = false;
          });
      });
    },
    deleteItem(shipment) {
      this.$http
        .post('/admin/shipments/update-statuses', {
          ids: [shipment.id],
          status: 4
        })
        .then(() => {
          this.$notify({
            group: 'main',
            title: 'Shipment Updated',
            text: `${shipment.ship_code || this.selected.name
              } shipment has been moved to ready`,
            duration: 4000
          });
          var index = this.selected.shipments.indexOf(shipment);
          this.selected.shipments.splice(index, 1);
          //          Event.fire('admin-update-shipments');
          this.getUsers();
        })
        .finally(() => { });
    },
    async completeContainer(){
      const isConfirm = await swal({
        title: 'Complete Container',
        text: `Complete ${this.container.name} ${this.container.type} ${this.container.day_code}. All shipments will be moved to in-transit and container will be closed.`,
        icon: 'warning',
        buttons: ['Cancel', 'Confirm']
      });

      if (isConfirm) {

        this.submitting = true;
        this.$http
          .post(`/admin/containers/${this.container.id}/complete`, this.container)
          .then(() => {
            this.closeDialog();
            this.$notify({
              group: 'main',
              title: 'Container Completed',
              text: `Container successfully completed!`,
              duration: 4000
            });
            this.$emit('update-containers');
          })
          .catch((err) => {
            this.onError(err);
          })
          .finally(() => {
            this.submitting = false;
          });
      }

    },
    async transfer() {

      const isConfirm = await swal({
        title: 'Transfer Shipments',
        text: `Transfer ${this.container.shipments_count} shipments from ${this.container.name} ${this.container.type} ${this.container.sort_number} to ${this.transferContainer.name} ${this.transferContainer.type} ${this.transferContainer.sort_number}`,
        icon: 'warning',
        buttons: ['Cancel', 'Confirm']
      });

      if (isConfirm) {
        this.submitting = true;
        this.$http
          .post(`/admin/containers/${this.container.id}/transfer`,
            {
              barcode: this.barcode
            })
          .then(() => {
            this.closeDialog();
            this.$notify({
              group: 'main',
              title: 'Transfer Success',
              text: `Container successfully transferred!`,
              duration: 4000
            });

            this.$emit('update-containers');
          })
          .catch((err) => {
            this.onError(err);
          })
          .finally(() => {
            this.submitting = false;
          });
      }
    },
    async remove() {

      const isConfirm = await swal({
        title: 'Remove Container',
        text: `Remove ${this.container.name} ${this.container.type} ${this.container.day_code} from ${this.container.parent_container.name} ${this.container.parent_container.type} ${this.container.parent_container.day_code}`,
        icon: 'warning',
        buttons: ['Cancel', 'Confirm']
      });

      if (isConfirm) {
        this.submitting = true;
        this.$http
          .post(`/admin/containers/${this.container.id}/remove`)
          .then(() => {
            this.closeDialog();
            this.$notify({
              group: 'main',
              title: 'Remove Success',
              text: `Container successfully removed!`,
              duration: 4000
            });

            this.$emit('update-containers');
          })
          .catch((err) => {
            this.onError(err);
          })
          .finally(() => {
            this.submitting = false;
          });
      }
    },
    onError(err) {
      if (
        err.response.status === 422 &&
        typeof err.response.data.errors !== 'undefined'
      ) {
        const errors = err.response.data.errors;
        let message = '';

        for (var field in errors) {
          message += `${errors[field][0]}\n`;
        }

        this.errorMessage(message);
      }
    }
  }
};
</script>
