<template>
  <v-form
    data-vv-scope="location-selector"
    class="location-selector"
  >
    <v-row>
      <v-col cols="12">
        <v-select
          v-model="localDropoffLocationId"
          v-validate="'required'"
          :error-messages="errs.collect('location-selector.dropoff_location')"
          data-vv-name="dropoff_location"
          dense
          outlined
          :items="dropoffLocations"
          label="Dropoff Location"
          hint="This location is where you will drop off your shipments."
          persistent-hint
          clearable
        />
      </v-col>

      <v-col cols="12">
        <v-select
          v-model="localPickupLocationId"
          v-validate="'required'"
          :error-messages="errs.collect('location-selector.pickup_location')"
          data-vv-name="pickup_location"
          dense
          outlined
          :items="pickupLocations"
          label="Pickup Location"
          hint="This location is where you will pickup your returns and supplies."
          persistent-hint
          clearable
        />
      </v-col>
      <v-col cols="12">
        <v-select
          v-model="localRegionId"
          v-validate="'required'"
          disabled
          :error-messages="errs.collect('location-selector.region')"
          data-vv-name="region"
          dense
          outlined
          item-text="name"
          item-value="id"
          :items="regions"
          label="Region"
          hint="Your region is based on the location you will be dropping off your shipments."
          persistent-hint
          clearable
        />
      </v-col>
    </v-row>
  </v-form>
</template>

<script>

export default {
  props: {
    postalCode: {
      type: String,
      required: true,
    },
    dropoffLocationId: {
      type: Number,
      default: null
    },
    regionId: {
      type: Number,
      default: null
    },
    pickupLocationId: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      allLocations: [],
      regions: [],
    };
  },
  computed: {
    pickupLocations() {
      return this.allLocations.filter(
        (branch) => branch.company_location && branch.region_id === this.regionId && branch.is_pickup_location
      );
    },
    dropoffLocations() {
      return this.allLocations.filter(
        (branch) => branch.is_dropoff_location
      );
    },
    localDropoffLocationId: {
      get() {
        return this.dropoffLocationId;
      },
      set(value) {
        this.$emit('update:dropoffLocationId', value);
      }
    },
    localRegionId: {
      get() {
        return this.regionId;
      },
      set(value) {
        this.$emit('update:regionId', value);
      }
    },
    localPickupLocationId: {
      get() {
        return this.pickupLocationId;
      },
      set(value) {
        this.$emit('update:pickupLocationId', value);
      }
    }
  },
  watch: {
    async postalCode(newPostalCode, oldPostalCode) {
      // remove whitespace from postal code
      if (newPostalCode === null || newPostalCode === undefined) {
        newPostalCode = '';
      }

      newPostalCode = newPostalCode.replace(/\s/g, '');

      if (newPostalCode !== oldPostalCode && newPostalCode.length === 6) {
        console.log('Postal code changed:', newPostalCode);
        await this.getBranches();
      }
    },
    localDropoffLocationId() {
      this.setRegionId();
    },
  },
  mounted() {
    this.getBranches();
    this.getRegions();
  },
  methods: {
    getRegions() {
      this.$http.get('/regions').then((response) => {
        this.regions = response.data;
      });
    },
    async getBranches() {
      try {
        // Get branches from the API which now include lat/lng coordinates
        const response = await this.$http.get(`/branches`);
        const branches = response.data;
        
        // Calculate distances using coordinates already provided in the response
        let originLat = 0, originLng = 0;
        if (this.postalCode && this.postalCode.length >= 6) {
          // For the user's location, we still need to get coordinates if we want to calculate distances
          // This is a single API call just for the user's postal code
          const geocodeResponse = await this.$http.get(`/geocode?postalCode=${encodeURIComponent(this.postalCode)}`);
          if (geocodeResponse.data && geocodeResponse.data.latitude && geocodeResponse.data.longitude) {
            originLat = geocodeResponse.data.latitude;
            originLng = geocodeResponse.data.longitude;
          }
        }
        
        // Calculate distances using the coordinates provided in the branches response
        const locationsWithDistance = branches.map(branch => {
          let distance = 0;
          
          // Only calculate distance if we have coordinates for both the user and the branch
          // Support both naming conventions (latitude/longitude and lat/lng)
          const branchLat = branch.latitude
          const branchLng = branch.longitude
          
          if (originLat !== 0 && originLng !== 0 && branchLat && branchLng) {
            distance = this.calculateDistance(
              originLat,
              originLng,
              branchLat,
              branchLng
            );
          }
          
          return { ...branch, distance };
        });

        // Format the locations with distance text
        this.allLocations = locationsWithDistance
          .sort((a, b) => a.distance - b.distance)
          .map((item) => {
            let distanceText = item.distance !== 0 ? `(${item.distance.toFixed(2)} km)` : '';
            return {
              value: item.id,
              text: `${item.city} - ${item.address1}, ${item.city} ${item.province_code}, ${item.postal_code} ${distanceText}`,
              latitude: item.latitude || null,
              longitude: item.longitude || null,
              region_id: item.region_id,
              postal_code: item.postal_code,
              company_location: item.company_location,
              is_dropoff_location: item.is_dropoff_location,
              is_pickup_location: item.is_pickup_location
            };
          });
      } catch (error) {
        console.error('Failed to get branches:', error);
      }
    },
    
    findClosestBranches() {
      // Since we no longer need to make API calls for each location,
      // we can simplify this to just return the locations sorted by distance
      return this.allLocations
        .sort((a, b) => a.distance - b.distance)
        .filter((location, index, array) => location.distance === array[0].distance)
        .map(({ value, text }) => ({ value, text }));
    },
    calculateDistance(lat1, lon1, lat2, lon2) {
      const R = 6371; // Radius of the Earth in kilometers
      const dLat = ((lat2 - lat1) * Math.PI) / 180;
      const dLon = ((lon2 - lon1) * Math.PI) / 180;
      const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(lat1 * (Math.PI / 180)) *
        Math.cos(lat2 * (Math.PI / 180)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      const distance = R * c; // Distance in kilometers
      return distance;
    },
    setRegionId() {
      const location = this.dropoffLocations.find(
        (location) => location.value === this.localDropoffLocationId
      );

      if (!location) {
        return;
      }

      this.localRegionId = location.region_id

      // if this is a company location, set the pickup location to the same location
      if (location.company_location) {
        this.localPickupLocationId = location.value;
      }
    },
    validate() {
      this.$validator.validateAll('location-selector').then(result => {
         this.$emit('validationResult', result);
      });
    }
  },
};
</script>
<style>
.location-selector .v-messages__message {
  font-size: 12px;
}
</style>