<template>
  <v-row dense>
    <confirm-password-dialog
      v-model="showConfirmPasswordDialog"
      @onSuccess="onSuccessConfirmPasswordDialog"
    />

    <two-factor-confirm-dialog
      v-model="showConfirm2faDialog"
      @onSuccess="onSuccessTurnOn2FA"
    />

    <two-factor-recovery-codes-dialog
      v-model="showTwoFactorRecoveryCodesDialog"
      :recovery-codes="recoveryCodes"
    />

    <!-- 2-Step Verification -->
    <v-col cols="12">
      <v-row dense>
        <v-col
          v-if="has2FAEnabled"
          cols="12"
        >
          <h4>Disable 2-Step Verification?</h4>
          <p>
            Turning off 2-Step Verification will remove the extra security
            of your account and you'll only use your password to sign in.
          </p>
          <v-btn
            color="primary"
            :disabled="loading"
            :loading="loading"
            @click="confirmTurnOff2FA"
          >
            Disable
          </v-btn>
        </v-col>

        <v-col
          v-else
          cols="12"
        >
          <h4>Enable 2-Step Verification?</h4>
          <p>
            Turning on 2-Step Verification will add extra security to your account. In addition to your password, you'll also need to provide an authentication code to sign in.
          </p>

          <v-btn
            color="primary"
            :disabled="loading"
            :loading="loading"
            @click="confirmTurnOn2FA"
          >
            Enable
          </v-btn>
        </v-col>

        <v-col
          v-if="has2FAEnabled"
          cols="12"
        >
          <br>
          <h4>Recovery Codes</h4>
          <p>
            Get your recovery codes to use in case you lose access to your
            device.
          </p>
          <v-btn
            color="primary"
            :disabled="loading"
            :loading="loading"
            @click="confirmGenerateRecoveryCodes"
          >
            Generate Recovery Codes
          </v-btn>
        </v-col>
      </v-row>
    </v-col>

    <!-- Trusted Device -->
    <two-factor-trusted-devices />
  </v-row>
</template>

<script>
const CONFIRM_PASSWORD_ACTIONS = {
  TURN_ON_2FA: 'TURN_ON_2FA',
  TURN_OFF_2FA: 'TURN_OFF_2FA',
  GET_RECOVERY_CODES: 'GET_RECOVERY_CODES'
};
import ConfirmPasswordDialog from '@/pages/auth/confirm-password-dialog.vue';
import TwoFactorConfirmDialog from '@/pages/auth/two-factor-confirm-dialog.vue';
import TwoFactorRecoveryCodesDialog from '@/pages/auth/two-factor-recovery-codes-dialog.vue';
import TwoFactorTrustedDevices from './TwoFactorTrustedDevices.vue';
export default {
  components: {
    ConfirmPasswordDialog,
    TwoFactorConfirmDialog,
    TwoFactorRecoveryCodesDialog,
    TwoFactorTrustedDevices
  },
  data() {
    return {
      loading: false,
      showConfirmPasswordDialog: false,
      confirmPasswordAction: null,
      showConfirm2faDialog: false,
      showTwoFactorRecoveryCodesDialog: false,
      recoveryCodes: []
    };
  },
  computed: {
    has2FAEnabled() {
      return this.$auth.user.has_two_factor_enabled;
    }
  },
  created() {},
  methods: {
    confirmGenerateRecoveryCodes() {
      this.confirmPasswordAction = CONFIRM_PASSWORD_ACTIONS.GET_RECOVERY_CODES;
      this.showConfirmPasswordDialog = true;
    },
    generateRecoveryCodes() {
      this.loading = true;
      // Try with leading slash first
      this.$http
        .post('/auth/user/two-factor-recovery-codes')
        .then((response) => {
          this.showTwoFactorRecoveryCodesDialog = true;
          this.recoveryCodes = response.data;
        })
        .catch((err) => {
          console.warn('Failed with leading slash, trying without', err);
          // Try without leading slash
          this.$http
            .post('auth/user/two-factor-recovery-codes')
            .then((response) => {
              this.showTwoFactorRecoveryCodesDialog = true;
              this.recoveryCodes = response.data;
            })
            .catch(() => {
              this.errorMessage('Unknown error occurred!');
            })
            .finally(() => {
              this.loading = false;
            });
        })
        .finally(() => {
          if (!this.recoveryCodes.length) {
            this.loading = false;
          }
        });
    },
    confirmTurnOff2FA() {
      this.confirmPasswordAction = CONFIRM_PASSWORD_ACTIONS.TURN_OFF_2FA;
      this.showConfirmPasswordDialog = true;
    },
    turnOff2FA() {
      this.loading = true;
      // Try with leading slash first
      this.$http
        .delete('/auth/user/two-factor-authentication')
        .then(() => {
          this.successMessage('2-Step Verification have been disabled!');
          this.$auth.user.has_two_factor_enabled = false;
        })
        .catch((firstErr) => {
          console.warn('Failed with leading slash, trying without', firstErr);
          // Try without leading slash
          this.$http
            .delete('auth/user/two-factor-authentication')
            .then(() => {
              this.successMessage('2-Step Verification have been disabled!');
              this.$auth.user.has_two_factor_enabled = false;
            })
            .catch((err) => {
              this.confirmPasswordAction = CONFIRM_PASSWORD_ACTIONS.TURN_OFF_2FA;
              this.openConfirmPasswordDialog(err);
            })
            .finally(() => {
              this.loading = false;
            });
        })
        .finally(() => {
          if (this.$auth.user.has_two_factor_enabled) {
            this.loading = false;
          }
        });
    },
    confirmTurnOn2FA() {
      this.confirmPasswordAction = CONFIRM_PASSWORD_ACTIONS.TURN_ON_2FA;
      this.showConfirmPasswordDialog = true;
    },
    turnOn2FA() {
      this.loading = true;
      // Try with leading slash first
      this.$http
        .post('/auth/user/two-factor-authentication')
        .then(() => {
          this.showConfirm2faDialog = true;
        })
        .catch((firstErr) => {
          console.warn('Failed with leading slash, trying without', firstErr);
          // Try without leading slash
          this.$http
            .post('auth/user/two-factor-authentication')
            .then(() => {
              this.showConfirm2faDialog = true;
            })
            .catch((err) => {
              this.confirmPasswordAction = CONFIRM_PASSWORD_ACTIONS.TURN_ON_2FA;
              this.openConfirmPasswordDialog(err);
            })
            .finally(() => {
              this.loading = false;
            });
        })
        .finally(() => {
          if (!this.showConfirm2faDialog) {
            this.loading = false;
          }
        });
    },
    onSuccessTurnOn2FA() {
      this.successMessage('2-Step Verification has been enabled!');
      this.$auth.user.has_two_factor_enabled = true;
    },
    onSuccessConfirmPasswordDialog() {
      switch (this.confirmPasswordAction) {
        case CONFIRM_PASSWORD_ACTIONS.TURN_ON_2FA:
          this.turnOn2FA();
          break;
        case CONFIRM_PASSWORD_ACTIONS.TURN_OFF_2FA:
          this.turnOff2FA();
          break;
        case CONFIRM_PASSWORD_ACTIONS.GET_RECOVERY_CODES:
          this.generateRecoveryCodes();
          break;
        default:
      }
    },
    openConfirmPasswordDialog(err) {
      if (err.response.status === 423) {
        this.showConfirmPasswordDialog = true;
      } else {
        this.errorMessage('Unknown error occurred!');
      }
    }
  }
};
</script>
