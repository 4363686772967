const filters = {
  defaultProduct() {
    return {
      id: null,
      name: null,
      sku: null,
      upc: null,

      override_name: false,
      override_weight: false,
      override_value: false,
      override_dimensions: false,

      weight: null,
      weight_unit: 'lbs',

      hs_code: null,
      customs_description: null,
      country_code_origin: null,
      declared_value: null,
      currency: 'USD',
      tag_ids: null,
      
      // Manufacturer information
      manufacturer_name: null,
      manufacturer_address1: null,
      manufacturer_city: null,
      manufacturer_province_code: null,
      manufacturer_country_code: null,
      manufacturer_postal_code: null
    };
  }
};

export default filters;
